import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
  Card,
  CardMedia,
  CardContent,
  Avatar,
  useTheme,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import physioTheme from "../../../theme2";

const testimonials = [
  {
    name: "Samantha M.",
    title: "Patient",
    quote:
      "I’ve been to several physiotherapists over the years, but Drax Hall Physiotherapy & Rehab stands out for its personalized care and attention to detail. From the moment I walked in, I felt welcomed and supported. The team took the time to understand my issues and tailor a treatment plan that truly worked for me. After just a few sessions, I noticed a significant improvement in my mobility and pain levels. I’m so grateful for their expertise and compassion!",
    image:
      "https://rosebushes.gracerosefarm.com/cdn/shop/files/Screenshot2024-04-24at6.53.48AM_800x.png?v=1713966885", // Replace with a suitable image if necessary
  },
  {
    name: "Mark W.",
    title: "Patient",
    quote:
      "I came to Drax Hall Physiotherapy after a sports injury, and I couldn’t be happier with the results. The physiotherapists are incredibly knowledgeable and focused on getting you back to your best. Their holistic approach, including massage therapy and rehabilitation exercises, made all the difference. I’m back to playing sports with no pain, and I feel stronger than ever!",
    image:
      "https://cdn.britannica.com/79/232779-050-6B0411D7/German-Shepherd-dog-Alsatian.jpg", // Replace with a suitable image if necessary
  },
  {
    name: "Dorothy B.",
    title: "Patient",
    quote:
      "As someone who suffers from chronic back pain, I’ve tried many treatments with little success. But after just a few sessions at Drax Hall Physiotherapy & Rehab, I’ve seen remarkable improvements. The staff is professional, friendly, and truly listens to my concerns. The combination of manual therapy and the personalized exercise plan has helped me manage my pain and improve my quality of life. I highly recommend them!",
    image:
      "https://images.fastcompany.com/image/upload/f_webp,c_fit,w_1920,q_auto/wp-cms-2/2024/09/004-91189733-lego-mclaren.jpg", // Replace with a suitable image if necessary
  },
];

const TestimonialSection = () => {
  const theme = useTheme();
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const handlePrev = () => {
    setCurrentTestimonial((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentTestimonial((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Box
      id="testimonials"
      sx={{
        backgroundColor: physioTheme.palette.info.main,
        padding: { xs: theme.spacing(2), md: theme.spacing(8) },
        color: "white",
        position: "relative",
      }}
    >
      {/* Large Quote Icon */}
      <Box
        sx={{
          position: "absolute",
          top: "-78px",
          left: "58px",
          backgroundColor: physioTheme.palette.secondary.main,
          padding: 4,
          borderRadius: "50%",
        }}
      >
        <FormatQuoteIcon
          sx={{
            fontSize: "100px",
            color: "white",
          }}
        />
      </Box>
      <Grid container spacing={4} justifyContent="space-between">
        {/* Left Section - Text and Heading */}
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              mt: { xs: "80px", md: "0" },
              marginBottom: "20px",
              zIndex: 1,
              color: "white",
              fontFamily: "Avenir Next LT Pro",
            }}
          >
            So what do our clients have to say?
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "30px", color: "white", fontFamily: "Roboto" }}
          >
            Hear from those who have experienced the transformative care at Drax
            Hall Physiotherapy & Rehab. Their stories speak to the quality of
            our personalized services.
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: 400, marginBottom: "10px", color: "white" }}
          >
            Have a question for us?
          </Typography>
          <Button
            variant="text"
            sx={{
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              backgroundColor: physioTheme.palette.secondary.main,
              boxShadow: "none",
              borderRadius: 8,
            }}
          >
            Contact Us
          </Button>
        </Grid>

        {/* Right Section - Testimonial Card */}
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 4,
              width: "100%",
              height: "100%",
            }}
          >
            {/* Navigation Icons */}
            <IconButton
              aria-label="previous"
              sx={{
                backgroundColor: physioTheme.palette.secondary.main,
                color: "white",
              }}
              onClick={handlePrev}
            >
              <ArrowBackIosIcon />
            </IconButton>

            <Card
              sx={{
                backgroundColor: "#ffffff",
                color: "black",
                borderRadius: "12px",
                position: "relative",
              }}
            >
              <CardMedia
                component="img"
                height="300"
                image={testimonials[currentTestimonial].image}
                alt={testimonials[currentTestimonial].name}
              />
              <CardContent sx={{ position: "relative", padding: "30px" }}>
                {/* Quote Icon and Testimonial */}
                <Avatar
                  sx={{
                    width: "60px",
                    height: "60px",
                    backgroundColor: physioTheme.palette.info.main,
                    color: "white",
                    position: "absolute",
                    top: "-30px",
                    left: "20px",
                  }}
                >
                  <FormatQuoteIcon sx={{ fontSize: "30px" }} />
                </Avatar>

                <Typography
                  variant="body1"
                  sx={{ fontStyle: "italic", marginBottom: "20px" }}
                >
                  "{testimonials[currentTestimonial].quote}"
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  {testimonials[currentTestimonial].name}
                </Typography>
                <Typography variant="body2" sx={{ color: "#7A7A7A" }}>
                  {testimonials[currentTestimonial].title}
                </Typography>
              </CardContent>
            </Card>

            <IconButton
              aria-label="next"
              sx={{
                backgroundColor: physioTheme.palette.secondary.main,
                color: "white",
              }}
              onClick={handleNext}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TestimonialSection;
