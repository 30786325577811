import React from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  // Checkbox,
  // FormControlLabel,
  IconButton,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsIcon from "@mui/icons-material/Directions";
import physioTheme from "../../../theme2";
import { Instagram } from "@mui/icons-material";

const StyledFormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "20px",
  padding: theme.spacing(10),
  [theme.breakpoints.up("xs")]: {
    padding: theme.spacing(2), // padding for xs screen size
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(8), // padding for md screen size
  },
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
}));

const ContactUs = () => {
  const theme = useTheme();

  // Function to handle form submission and open email client
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Collect form data (optional for further use)
    const formData = new FormData(e.target as HTMLFormElement);

    const emailBody = `
      First Name: ${formData.get("firstName")}
      Last Name: ${formData.get("lastName")}
      Email: ${formData.get("email")}
      Subject: ${formData.get("subject")}
      Message: ${formData.get("message")}
    `;

    // Open the email client with prefilled data
    window.location.href = `mailto:draxhallphysio@gmail.com?subject=${formData.get(
      "subject"
    )}&body=${encodeURIComponent(emailBody)}`;
  };

  return (
    <Box
      id="contact-us"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `url('/contact-us-bg.png') no-repeat center center`,
        backgroundSize: "cover",
        height: "auto",
        width: "100%",
        mb: theme.spacing(24),
        py: theme.spacing(16),
        px: { xs: theme.spacing(5), md: theme.spacing(15) },
      }}
    >
      <StyledFormContainer>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          mb={theme.spacing(6)}
        >
          Contact Us
        </Typography>

        <Typography
          variant="body1"
          align="center"
          gutterBottom
          mb={theme.spacing(6)}
          px={{ xs: theme.spacing(5), md: theme.spacing(10) }}
        >
          For inquiries or to book an appointment, please complete the form
          below. A member of our team will respond within 24 hours.
        </Typography>

        {/* Form */}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="First name"
                fullWidth
                variant="standard"
                name="firstName"
                InputProps={{
                  sx: {
                    color: "#262262",
                    borderColor: "#262262",
                  },
                }}
                InputLabelProps={{
                  sx: { color: "#262262" },
                }}
                sx={{
                  fieldset: { borderColor: "#262262" },
                  "& .MuiOutlinedInput-root:hover fieldset": {
                    borderColor: "#262262",
                  },
                }}
              />
            </Grid>

            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last name"
                fullWidth
                variant="standard"
                name="lastName"
                InputProps={{
                  sx: {
                    color: "#262262",
                    borderColor: "#262262",
                  },
                }}
                InputLabelProps={{
                  sx: { color: "#262262" },
                }}
                sx={{
                  fieldset: { borderColor: "#262262" },
                  "& .MuiOutlinedInput-root:hover fieldset": {
                    borderColor: "#262262",
                  },
                }}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Email address"
                fullWidth
                variant="standard"
                name="email"
                InputProps={{
                  sx: {
                    color: "#262262",
                    borderColor: "#262262",
                  },
                }}
                InputLabelProps={{
                  sx: { color: "#262262" },
                }}
                sx={{
                  fieldset: { borderColor: "#262262" },
                  "& .MuiOutlinedInput-root:hover fieldset": {
                    borderColor: "#262262",
                  },
                }}
              />
            </Grid>

            {/* Subject */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Subject"
                fullWidth
                variant="standard"
                name="subject"
                InputProps={{
                  sx: {
                    color: "#262262",
                    borderColor: "#262262",
                  },
                }}
                InputLabelProps={{
                  sx: { color: "#262262" },
                }}
                sx={{
                  fieldset: { borderColor: "#262262" },
                  "& .MuiOutlinedInput-root:hover fieldset": {
                    borderColor: "#262262",
                  },
                }}
              />
            </Grid>

            {/* Message */}
            <Grid item xs={12}>
              <TextField
                label="Leave us a message..."
                multiline
                rows={4}
                fullWidth
                variant="standard"
                name="message"
                InputLabelProps={{
                  sx: { color: "#262262" },
                }}
                sx={{
                  fieldset: { borderColor: "#262262" },
                  "& .MuiOutlinedInput-root:hover fieldset": {
                    borderColor: "#262262",
                  },
                }}
              />
            </Grid>

            {/* Privacy Policy
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox sx={{ color: "#262262" }} />}
                label={
                  <Typography variant="body2" sx={{ color: "#262262" }}>
                    You agree to our friendly{" "}
                    <a
                      href="/privacy-policy"
                      style={{ color: "#262262", textDecoration: "underline" }}
                    >
                      privacy policy.
                    </a>
                  </Typography>
                }
              />
            </Grid> */}

            {/* Send Button */}
            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  padding: "12px",
                  backgroundColor: "#262262",
                  color: "white",
                  alignSelf: "flex-end",
                  boxShadow: "none",
                }}
              >
                Send message
              </Button>
            </Grid>
          </Grid>
        </form>

        {/* Social Media and Contact Information */}
        <Grid
          container
          mt={4}
          sx={{
            justifyContent: "space-between",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: 1,
              marginBottom: 2,
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                marginBottom: 2,
                justifyContent: "start",
              }}
            >
              <LocationOnIcon />
              <Typography>
                Shop #3, Knutsford Express Business Centre, 12 Drax Hall Estate,
                St Ann
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                marginBottom: 2,
                justifyContent: "start",
              }}
            >
              <PhoneIcon />
              <Typography>
                Landline: 876-972-7155 | Cell/WhatsApp: 876-390-8514
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                marginBottom: 2,
                justifyContent: "start",
              }}
            >
              <EmailIcon />
              <Typography>draxhallphysio@gmail.com</Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: 1,
              marginBottom: 2,
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                marginBottom: 2,
                justifyContent: "start",
              }}
            >
              <AccessTimeIcon />
              <Typography>
                Mon-Fri: 8:30 AM - 4:30 PM | Sat: 9:00 AM - 4:00 PM
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                marginBottom: 2,
                justifyContent: "start",
              }}
            >
              <DirectionsIcon />
              <Typography>
                Behind Knutsford Express Building, Drax Hall, St Ann
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* Social Media Icons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/draxhallphysio_rehab/"
            target="_blank"
            sx={{ backgroundColor: physioTheme.palette.primary.main }}
          >
            <Instagram sx={{ color: "#fff" }} />
          </IconButton>
          {/* <IconButton
            color="inherit"
            href="https://twitter.com"
            sx={{ backgroundColor: physioTheme.palette.primary.main }}
          >
            <TwitterIcon sx={{ color: "#fff" }} />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://linkedin.com"
            sx={{ backgroundColor: physioTheme.palette.primary.main }}
          >
            <LinkedInIcon sx={{ color: "#fff" }} />
          </IconButton> */}
        </Box>
      </StyledFormContainer>
    </Box>
  );
};

export default ContactUs;
