export interface Treatment {
  name: string;
  link: string;
}

export interface CareOption {
  title: string;
  subtitle: string;
  treatments: Treatment[];
  description?: string;
  viewAllLink: string;
  image: string;
  altText: string;
}

export const careOptionsData: CareOption[] = [
  {
    title: "Overview",
    subtitle: "Care options by body part",
    description:
      "From head to toe, we’ve got you covered. Powered by advanced technologies and techniques, our team of leading orthopedic specialists give you the time and treatments you need to keep active.",
    treatments: [],
    viewAllLink: "#",
    image:
      "https://www.apollospectra.com/backend/web/uploads/1710946678.jpg",
    altText: "Overview image",
  },
  {
    title: "Back & Neck",
    subtitle: "Heal back & neck pain",
    treatments: [
      { name: "Anterior Cervical Corpectomy & Discectomy", link: "#" },
      { name: "Artificial Disc Replacement (ADR)", link: "#" },
      { name: "Bone Cement Injection", link: "#" },
      { name: "Degenerative Disc Disease", link: "#" },
      { name: "Diffuse Idiopathic Skeletal Hyperostosis (DISH)", link: "#" },
      { name: "Discectomy", link: "#" },
      { name: "Discitis Treatment & Information", link: "#" },
      { name: "Epidural Injections for Spinal Pain", link: "#" },
      { name: "Interlaminar Implants", link: "#" },
      { name: "Interlaminar Lumbar Instrumental Fusion: ILIF", link: "#" },
      { name: "Kyphoplasty (Balloon Vertebroplasty)", link: "#" },
      { name: "Kyphosis", link: "#" },
      { name: "Laminectomy: Decompression Surgery", link: "#" },
      { name: "Lumbar Epidural Steroid Injection", link: "#" },
      { name: "Lumbar Interbody Fusion (IBF)", link: "#" },
      { name: "Outpatient Spine Surgery", link: "#" },
      { name: "Pinched Nerve", link: "#" },
      { name: "Piriformis Syndrome", link: "#" },
      { name: "Sacroiliac Joint Pain", link: "#" },
      { name: "Sciatica", link: "#" },
      { name: "Spinal Fusion", link: "#" },
      { name: "Spondylolisthesis & Spondylolysis", link: "#" },
      { name: "Vertebroplasty", link: "#" },
      { name: "Whiplash & Whiplash Associated Disorder (WAD)", link: "#" },
    ],
    viewAllLink: "#",
    image:
      "https://www.pacificrehabilitation.com/wp-content/uploads/2021/05/man-with-neck-and-back-pain.jpg",
    altText: "Man holding neck",
  },
  {
    title: "Elbow",
    subtitle: "Elbow pain relief",
    treatments: [
      { name: "Arthroscopic Debridement of the Elbow", link: "#" },
      { name: "Aspiration of the Olecranon Bursa", link: "#" },
      { name: "Cubital Tunnel Syndrome", link: "#" },
      { name: "Elbow Bursitis", link: "#" },
      { name: "Elbow Injuries & Inner Elbow Pain in Throwing Athletes", link: "#" },
      { name: "Golfer’s Elbow", link: "#" },
      { name: "Growth Plate Injuries of the Elbow", link: "#" },
      { name: "Hyperextension Injury of the Elbow", link: "#" },
      { name: "Little Leaguer’s Elbow (Medial Apophysitis)", link: "#" },
      { name: "Olecranon Stress Fractures", link: "#" },
      { name: "Radial Tunnel Syndrome", link: "#" },
      { name: "Tennis Elbow Treatment", link: "#" },
      { name: "Tricep Pain & Tendonitis", link: "#" },
      { name: "UCL (Ulnar Collateral Ligament) Injuries", link: "#" },
      { name: "Valgus Extension Overload", link: "#" },
    ],
    viewAllLink: "#",
    image:
      "https://media.istockphoto.com/id/470798661/photo/painful-elbow-joint.jpg?s=612x612&w=0&k=20&c=hPYfsGodTkBjuvCJeM2lL-S1m5wgPQcHjouLYpgfzuE=",
    altText: "Elbow pain relief image",
  },
  {
    title: "Foot & Ankle",
    subtitle: "Heal foot & ankle pain",
    treatments: [
      { name: "Achilles Calcific Tendinitis", link: "#" },
      { name: "Achilles Tendon Rupture", link: "#" },
      { name: "Ankle Fracture Surgery", link: "#" },
      { name: "Ankle Fractures (Broken Ankle)", link: "#" },
      { name: "Ankle Fusion Surgery", link: "#" },
      { name: "Arthroscopic Articular Cartilage Repair", link: "#" },
      { name: "Arthroscopy of the Ankle", link: "#" },
      { name: "Bunions", link: "#" },
      { name: "Charcot Joint", link: "#" },
      { name: "Common Foot Fractures in Athletes", link: "#" },
      { name: "Foot Stress Fractures", link: "#" },
      { name: "Hallux Rigidus Surgery – Cheilectomy", link: "#" },
      { name: "Hammer Toe", link: "#" },
      { name: "High Ankle Sprain (Syndesmosis Ligament Injury)", link: "#" },
      { name: "Lisfranc Injuries", link: "#" },
      { name: "Mallet, Hammer & Claw Toes", link: "#" },
      { name: "Metatarsalgia", link: "#" },
      { name: "Neuromas (Foot)", link: "#" },
      { name: "Plantar Fasciitis", link: "#" },
      { name: "Sprained Ankle", link: "#" },
      { name: "Total Ankle Replacement", link: "#" },
      { name: "Turf Toe", link: "#" },
    ],
    viewAllLink: "#",
    image:
      "https://www.capefearortho.com/wp-content/uploads/2020/07/Depositphotos_9446931_xl-2015-1024x1015.jpg",
    altText: "Foot and ankle care image",
  },
  {
    title: "Hand & Wrist",
    subtitle: "Care for hand & wrist pain",
    treatments: [
      { name: "Basal Joint Surgery", link: "#" },
      { name: "Carpal Tunnel Syndrome", link: "#" },
      { name: "De Quervain’s Tenosynovitis", link: "#" },
      { name: "Dislocated Finger", link: "#" },
      { name: "Distal Radius Fracture (Broken Wrist)", link: "#" },
      { name: "Dupuytren’s Disease", link: "#" },
      { name: "Flexor Tendonitis", link: "#" },
      { name: "Fractured Fingers", link: "#" },
      { name: "Functional Nerve Transfers of the Hand", link: "#" },
      { name: "Ganglion Cysts", link: "#" },
      { name: "Hand & Finger Replantation", link: "#" },
      { name: "Hand Nerve Decompression", link: "#" },
      { name: "Hand Skin Grafts", link: "#" },
      { name: "Nerve Pain", link: "#" },
      { name: "Peripheral Nerve Surgery (Hand)", link: "#" },
      { name: "Revascularization of the Hand", link: "#" },
      { name: "Sports Wrist & Hand Injuries", link: "#" },
      { name: "Sprained Wrist", link: "#" },
      { name: "Trigger Finger", link: "#" },
      { name: "Ulnar Neuritis", link: "#" },
    ],
    viewAllLink: "#",
    image:
      "https://manorviewpractice.co.uk/wp-content/uploads/2024/04/Wrist-ice-and-heat-treatment.png",
    altText: "Hand and wrist care image",
  },
  {
    title: "Hip & Thigh",
    subtitle: "Hip pain care",
    treatments: [
      { name: "Avascular Necrosis (Osteonecrosis)", link: "#" },
      { name: "Bone Health Clinic", link: "#" },
      { name: "Groin Strains & Pulls", link: "#" },
      { name: "Hamstring Injuries", link: "#" },
      { name: "Hip Arthroscopy", link: "#" },
      { name: "Hip Dislocation", link: "#" },
      { name: "Hip Flexor Strains", link: "#" },
      { name: "Hip Fractures", link: "#" },
      { name: "Hip Hemiarthroplasty", link: "#" },
      { name: "Hip Impingement Labral Tears", link: "#" },
      { name: "Osteoarthritis of the Hip", link: "#" },
      { name: "Sports Hernias (Athletic Pubalgia)", link: "#" },
      { name: "Thigh Fractures", link: "#" },
    ],
    viewAllLink: "#",
    image:
      "https://www.sportsandspinal.net.au/wp-content/uploads/2019/10/Hip-pain-runner-e1570701988593.jpg",
      altText: "Hip and Thigh care image",
  }]

