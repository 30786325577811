import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  ListItemIcon,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ScheduleIcon from "@mui/icons-material/Schedule";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const PatientInfo: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      id="patient-info"
      sx={{ py: 8, px: { xs: 2, sm: 4, md: 12 }, backgroundColor: "#f9f9f9" }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold", color: theme.typography.h1.color, mb: 15 }}
      >
        Patient Information
      </Typography>

      {/* Registration and Vitals */}
      <Paper elevation={1} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "#DE0C78", fontWeight: "bold" }}
        >
          Registration & Vitals
        </Typography>
        <Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <EmojiPeopleIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>Registration</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Registration is done with the Nurse/Receptionist. Ensure all
                required personal and medical details are provided during the
                process.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <AccessTimeIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>Vitals Check</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Vitals will be taken by a Practical Nurse or Registered Nurse
                before your consultation.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>

      {/* Consent Form and Payment */}
      <Paper elevation={1} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "#DE0C78", fontWeight: "bold" }}
        >
          Consent Form & Payment
        </Typography>
        <Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>Consent Form</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A Consent Form will be given to review if you are doing any
                procedure with us. It must be signed by both the
                doctor/specialist and the patient or guardian.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <CreditCardIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>Payment Slip</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                After the visit, the patient will be provided with a slip by the
                specialist for payments to be made at the receptionist area.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>

      {/* Insurance Policies */}
      <Paper elevation={1} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "#DE0C78", fontWeight: "bold" }}
        >
          Insurance Policies
        </Typography>
        <Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <LocalHospitalIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>Accepted Insurance</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We accept the following insurance types: Sagicor, Guardian Life,
                and Canopy. Please have your insurance card ready for
                verification.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <LocalHospitalIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>Payment Methods</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We accept payments via Cash, Cheque, Debit, and Credit Cards.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>

      {/* FAQs */}
      <Paper elevation={1} sx={{ p: 4, borderRadius: 2 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "#DE0C78", fontWeight: "bold" }}
        >
          FAQs
        </Typography>

        {/* General Information */}
        <Typography
          variant="h6"
          gutterBottom
          sx={{ color: "#DE0C78", fontWeight: "bold" }}
        >
          General Information
        </Typography>

        <Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>What are your hours of operation?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>We are open from 8 am to 6 pm.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>How do I contact the facility?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Phone Numbers: Landline (876) 972-0514 / WhatsApp (876) 236-5215
                <br />
                Email: draxhallmedical@gmail.com.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>What type of insurance do you accept?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We accept all major health insurance providers including
                Sagicor, Guardian Life, and Canopy.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Appointments and Scheduling */}
        <Typography
          variant="h6"
          gutterBottom
          sx={{ color: "#DE0C78", fontWeight: "bold", mt: 4 }}
        >
          Appointments and Scheduling
        </Typography>

        <Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>How do I schedule an appointment?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                To make an appointment you may call us at (876) 972-0514,
                WhatsApp us at (876) 236-5215, or send an email to
                draxhallmedical@gmail.com.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>What is your cancellation policy?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Please give us a call if you won't be able to make your
                appointment.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>
                Can I request a special doctor or specialist?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, you may request a specific doctor or surgeon when
                scheduling your appointment.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Medical Records and Billing */}
        <Typography
          variant="h6"
          gutterBottom
          sx={{ color: "#DE0C78", fontWeight: "bold", mt: 4 }}
        >
          Medical Records and Billing
        </Typography>

        <Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>How do I obtain my medical records?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                To obtain your medical records, please contact us. If it is
                being requested by a company on your behalf, they should provide
                a signed consent form.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>How do I pay my bill?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Bills can be paid in-office by Cash, Debit, or Credit Card, or
                via Direct Deposit to National Commercial Bank (Savings Account
                544571575).
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Patient Care */}
        <Typography
          variant="h6"
          gutterBottom
          sx={{ color: "#DE0C78", fontWeight: "bold", mt: 4 }}
        >
          Patient Care
        </Typography>

        <Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>What should I bring to my appointment?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Please bring your ID, Insurance Card, Prescription, Medication
                List, Reports, CDs, X-rays, Ultrasound, etc.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>How long will the appointment take?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The duration of the appointment depends on the type of visit and
                the doctor you request, as well as whether any procedures are
                needed.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemIcon>
                <HelpOutlineIcon sx={{ color: "#DE0C78" }} />
              </ListItemIcon>
              <Typography>Can I bring a Family Member or Friend?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, you may bring someone with you to your appointment.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>
    </Box>
  );
};

export default PatientInfo;
