import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import MedicalPage from "./pages/MedicalPage";
import DentalPage from "./pages/DentalPage";
import PhysiotherapyPage from "./pages/PhysiotherapyPage";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline"; // Optional, for normalizing styles
import theme from "./theme"; // Import the theme file you created
import ScreenWrapper from "./components/ScreenWrapper";
import OrthopedicPage from "./pages/OrthopedicPage";
import EndoscopyColonoscopyPage from "./pages/Endoscopy";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline ensures consistent styling across browsers */}
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/medical"
            element={
              <ScreenWrapper>
                <MedicalPage />
              </ScreenWrapper>
            }
          />
          <Route
            path="/dental"
            element={
              <ScreenWrapper>
                <DentalPage />
              </ScreenWrapper>
            }
          />
          <Route
            path="/physiotherapy"
            element={
              <ScreenWrapper>
                <PhysiotherapyPage />
              </ScreenWrapper>
            }
          />
          <Route
            path="/orthopedic"
            element={
              <ScreenWrapper>
                <OrthopedicPage />
              </ScreenWrapper>
            }
          />
          <Route
            path="/endoscopy"
            element={
              <ScreenWrapper>
                <EndoscopyColonoscopyPage />
              </ScreenWrapper>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
