import React from "react";
import { Paper, Typography, Box, Grid, List, ListItem } from "@mui/material";

// Interface for the additional content items
interface AdditionalContentItem {
  title: string;
  description: string;
}

interface InfoSectionProps {
  id: string;
  title: string;
  content: React.ReactNode;
  imageUrls?: string[];
  imagePosition?: "left" | "right"; // New prop to control image positioning
  additionalContent?: AdditionalContentItem[]; // New prop for structured content
}

const InfoSection: React.FC<InfoSectionProps> = ({
  id,
  title,
  content,
  imageUrls,
  imagePosition = "left", // Default to "left"
  additionalContent,
}) => {
  return (
    <Box sx={{ mb: 6 }} id={id}>
      <Paper elevation={3} sx={{ p: 4, boxShadow: 2, borderRadius: 2 }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {/* Conditionally render the image and text based on the imagePosition */}
          {imagePosition === "left" && imageUrls?.[0] && (
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  height: { xs: 300, md: 400 },
                  backgroundImage: `url(${imageUrls[0]})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 2,
                  boxShadow: "none",
                }}
              />
            </Grid>
          )}

          {/* Text Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body1" paragraph>
              {content}
            </Typography>

            {/* Additional Content (list of reasons, etc.) */}
            {additionalContent && additionalContent.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Reasons for the Procedure:
                </Typography>
                <List>
                  {additionalContent.map((item, index) => (
                    <ListItem key={index}>
                      <Box sx={{ width: "100%" }}>
                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                          {item.title}
                        </Typography>
                        <Typography variant="body2">{item.description}</Typography>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Grid>

          {/* If imagePosition is "right", render the image after the text */}
          {imagePosition === "right" && imageUrls?.[0] && (
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  height: { xs: 300, md: 400 },
                  backgroundImage: `url(${imageUrls[0]})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 2,
                  boxShadow: "none",
                }}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default InfoSection;
