import { ReactElement } from "react";
import {
  FitnessCenter,
  HealthAndSafety,
  MedicalServices,
} from "@mui/icons-material";
import { ButtonPropsColorOverrides } from "@mui/material/Button";
import { OverridableStringUnion } from "@mui/types";

// Define the type for a single button object
// Define the type for a single button object
interface HomePageButton {
  to: string;
  icon: ReactElement;
  label: string;
  description: string; // Added description field
}

// Define the array of buttons with the correct type
export const HomePageButtons: HomePageButton[] = [
  {
    to: "/medical",
    icon: (
      <img
        src="/med.jpg"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    ),
    label: "Drax Hall Medical & Surgical",
    description: "Comprehensive medical and surgical services.",

  },
  {
    to: "/dental",
    icon: (
      <img
        src="/dental.jpg"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    ),
    label: "Drax Hall Family Dental",
    description: "Quality dental care for the whole family.",
  },
  {
    to: "/physiotherapy",
    icon: (
      <img
        src="/physio.jpg"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    ),
    label: "Drax Hall Physiotherapy & Rehab",
    description: "Professional physiotherapy and rehabilitation services.",
  },
];
