import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  IconButton,
  Collapse,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Staff } from "../types";

interface StaffCardProps {
  staff: Staff;
}

const placeholder = "/placeholder-person.webp";

const StaffCard: React.FC<StaffCardProps> = ({ staff }) => {
  const [expanded, setExpanded] = useState(false); // State to track expansion
  const theme = useTheme();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: expanded ? "auto" : "400px", // Set fixed height for collapsed state, auto for expanded
        borderRadius: "10px",
        position: "relative",
        transition: "height 0.3s ease", // Smooth height transition
        boxShadow: expanded ? theme.shadows[6] : theme.shadows[3], // Slightly deeper shadow when expanded
        overflow: "visible"
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          position: "relative",
          borderRadius: "10px",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background:
              "transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%)",
            zIndex: 0,
          },
        }}
      >
        <CardMedia
          component="img"
          src={staff?.image?.length ? staff?.image : placeholder}
          alt={`${staff?.name ?? ""}`}
          sx={{
            width: "100%",
            height: 300, // Set a fixed height for the image
            objectFit: "contain",
          }}
        />
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: 5,
            left: theme.spacing(2),
            zIndex: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              width: "90%",
              color: "white",
            }}
          >
            {`${staff?.name ?? ""}`}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: "90%",
              color: theme.palette.info.main,
            }}
          >
            {`${staff?.title ?? ""}`}
          </Typography>
        </Box>
      </Box>

      {/* Content Section */}
      <CardContent
        sx={{
          flexGrow: 1,
          padding: theme.spacing(2),
          overflow: "hidden", // Ensures the content doesn't overflow in collapsed state
        }}
      >
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: expanded ? "none" : 2, // Truncate to 3 lines in collapsed state
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {staff?.bio ?? "Bio Unavailable"}
        </Typography>

        <Collapse in={expanded} timeout={1500} unmountOnExit>
          {staff?.expertise_areas && (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  marginTop: theme.spacing(2),
                  color: theme.typography.h1.color
                }}
              >
                Expertise
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {staff?.expertise_areas.join(", ") ??
                  "Expertise Areas Unavailable"}
              </Typography>
            </Box>
          )}

          {staff?.qualifications && (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  marginTop: theme.spacing(2),
                  color: theme.typography.h1.color
                }}
              >
                Qualifications
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {staff?.qualifications.join(", ") ??
                  "Qualifications Unavailable"}
              </Typography>
            </Box>
          )}
        </Collapse>
      </CardContent>

     {/* Expand/Collapse Button */}
     <IconButton
        onClick={handleExpandClick}
        sx={{
          // transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease-in-out",
          position: "absolute",
          bottom: "-20px", // Move the button outside the card
          left: "50%", // Center horizontally
          transform: expanded
            ? "translateX(-50%) rotate(180deg)"
            : "translateX(-50%) rotate(0deg)", // Center horizontally and rotate on expand
          backgroundColor: "white",
          boxShadow: theme.shadows[3],
          border: `1px solid ${theme.palette.grey[300]}`, // Add a border to give it a "floating" effect
          "&:hover": {
            backgroundColor: theme.palette.grey[300],
          },
          zIndex: 2
        }}
      >
        <ExpandMoreIcon sx={{color: theme.palette.accent.main}}/>
      </IconButton>
    </Card>
  );
};

export default StaffCard;
