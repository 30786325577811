import React, { useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  Avatar,
  Dialog,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/system";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import HealingIcon from "@mui/icons-material/Healing";
import SpaIcon from "@mui/icons-material/Spa";
import theme from "../../../theme";
import FacilitiesAndMethods from "./FacilitiesAndMethods";

const services = [
  {
    title: "Clinic Facilities",
    description:
      "Modern treatment rooms equipped with state-of-the-art physiotherapy and rehabilitation technology.",
    details:
      "Our facilities include ultrasound, electrotherapy devices, and a fully equipped gym for therapeutic exercises. Massage and spa rooms provide relaxation and support a holistic approach to wellness.",
    icon: <SpaIcon fontSize="large" sx={{ color: "white" }} />,
    image: "https://www.gulfphysio.com/wp-content/uploads/2022/08/Physical-therapy-clinic-1.png",
  },
  {
    title: "Rehabilitation",
    description:
      "Customized programs to restore function and mobility after surgery, stroke, or injury.",
    details:
      "Programs include physiotherapy, clinical massage, and therapeutic exercises tailored to your recovery goals.",
    icon: <HealingIcon fontSize="large" sx={{ color: "white" }} />,
    image: "https://painhero.ca/wp-content/uploads/Bounce-Back-Physio-Rehab-background.jpg",
  },
  {
    title: "Sports Therapy",
    description:
      "Preventative care, injury recovery, and performance optimization for athletes.",
    details:
      "Our sports therapy services include injury prevention, rehabilitation, and functional exercises to enhance performance.",
    icon: <FitnessCenterIcon fontSize="large" sx={{ color: "white" }} />,
    image: "https://peakephysicaltherapy.com/wp-content/uploads/sports-therapy-baltimore-md.jpg",
  },
  {
    title: "Pain Management",
    description: "Targeted techniques to manage chronic and acute pain.",
    details:
      "We use manual therapy, dry needling, and therapeutic exercises to relieve discomfort and promote healing.",
    icon: <LocalHospitalIcon fontSize="large" sx={{ color: "white" }} />,
    image: "https://www.physio.co.uk/images/pain/pain1.jpg",
  },
  {
    title: "Massage Therapy",
    description: "Therapeutic massage for relaxation and muscle recovery.",
    details:
      "Our massage therapy improves circulation, reduces muscle tension, and enhances overall well-being.",
    icon: <SpaIcon fontSize="large" sx={{ color: "white" }} />,
    image: "https://www.ccp.edu/sites/default/files/images/Specialty_Pages/Noncredit%20Programs/Massage%20Therapy%20Student.jpg",
  },
  {
    title: "Clinical Massage Therapy",
    description:
      "Targeted therapeutic techniques to address musculoskeletal issues.",
    details:
      "This approach blends therapeutic techniques to focus on specific problem areas for chronic pain or injury recovery.",
    icon: <HealingIcon fontSize="large" sx={{ color: "white" }} />,
    image: "https://blog.healthjobsnationwide.com/wp-content/uploads/2021/10/massage-therapist-1024x683.png",
  },
];

const ServiceCard = styled(Card)(({ theme }) => ({
  borderRadius: "16px",
  textAlign: "center",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  padding: theme.spacing(4),
  backgroundColor: "white",
  position: "relative",
  overflow: "visible",
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: -28,
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: theme.palette.primary.main,
  width: 56,
  height: 56,
  borderRadius: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Services: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<{
    title: string;
    description: string;
    details: string;
    image: string;
  } | null>(null);

  const handleOpen = (service: any) => {
    setSelectedService(service);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedService(null);
  };

  return (
    <div id="services" style={{width: "100%", height: "auto"}}>
      <Box
        sx={{
          py: 8,
          textAlign: "center",
          px: { xs: theme.spacing(5), md: theme.spacing(15) },
        }}
      >
        <Typography
          variant="h3"
          sx={{ mb: 5, fontWeight: "bold", color: "primary.main" }}
        >
          Our Services
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: "black",
            marginBottom: 8,
            textAlign: "center",
          }}
        >
          At Drax Hall Physiotherapy & Rehab, we provide a full range of
          physiotherapy services, each tailored to meet the individual needs of
          our clients:
        </Typography>

        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="stretch"
        >
          {services.map((service, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{ display: "flex", mb: theme.spacing(5) }}
            >
              <ServiceCard>
                <IconWrapper>
                  <Avatar
                    sx={{
                      backgroundColor: "primary.main",
                      width: 56,
                      height: 56,
                    }}
                  >
                    {service.icon}
                  </Avatar>
                </IconWrapper>

                <CardContent sx={{ pt: 5 }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                    {service.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 3 }}
                  >
                    {service.description}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpen(service)}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </ServiceCard>
            </Grid>
          ))}
        </Grid>

        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          {selectedService && (
            <DialogContent>
              <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
                <Box
                  component="img"
                  src={selectedService.image}
                  alt={selectedService.title}
                  sx={{ width: { xs: "100%", md: "50%" }, borderRadius: 2 }}
                />
                <Box sx={{ p: 3 }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                    {selectedService.title}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {selectedService.details}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          )}
        </Dialog>
      </Box>
      <FacilitiesAndMethods />
    </div>
  );
};

export default Services;
