import React from "react";
import { Box, Typography, Button, Container, useTheme } from "@mui/material";
import dentalTheme from "../../../theme1";

function Hero() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: "url(/dental.jpeg)", // Replace with actual image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        textAlign: "center",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background:
            "transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%)",
          zIndex: 0,
        },
      }}
    >
      <Box sx={{ position: "absolute", width: "100%", zIndex: 1 }}>
        <Container>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: { xs: "2rem", sm: "2.5rem", md: "4rem" },
              fontFamily: "Avenir Next LT Pro",
              fontWeight: "bold",
              color: "white",
              pt: 8,
            }}
          >
            Welcome to Your Dental Home
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              mb: 4,
              color: "white",
              fontSize: ["0.75rem", "1rem", "1.25rem", "1.5rem"],
            }}
          >
            Welcome to our dental practice, where your family's oral health is
            at the heart of everything we do. We are dedicated to providing the
            highest quality dental care using the latest techniques in a warm
            and friendly environment. Our team is passionate about ensuring that
            every visit is a positive experience, whether you're here for a
            routine checkup, a cosmetic treatment, or specialized care for your
            children. We look forward to helping you and your family achieve and
            maintain beautiful, healthy smiles.
          </Typography>
          <Button
            variant="contained"
            href="#contact-us"
            sx={{
              textTransform: "none",
              backgroundColor: dentalTheme.palette.primary.main,
              py: 1,
              px: 5,
              color: "white",
              borderRadius: 8,
              width: "fit-content",
              boxShadow: "none",
              "&:hover": {
                background: dentalTheme.palette.primary.main,
                boxShadow: "none",
              },
            }}
          >
            Book an Appointment
          </Button>
        </Container>
      </Box>
    </Box>
  );
}

export default Hero;
