import React, { useEffect, useState } from "react";
import HeroSection from "./components/HeroSection";
import SpecialtyList from "./components/SpecialtyList";
import CareOptionsTabs from "./components/CareOptionsTabs";
import UrgentCareSection from "./components/UrgentCareSection";
import CallToActionSection from "./components/CallToActionSection";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fab, Zoom } from "@mui/material";
import { useLocation } from "react-router-dom";

const OrthopedicPage: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1)); // Remove the '#' to get the ID
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the element
      }
    }
  }, [location.hash]);

  // Track scrolling to toggle visibility of the scroll to top button
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <main id="main-content-wrap" tabIndex={-1} aria-label="Main Content">
        <HeroSection />
        <SpecialtyList />
        <CareOptionsTabs />
        {/* <UrgentCareSection /> */}
        <CallToActionSection />
        <Footer />
      </main>
      {/* Scroll to Top Button */}
      <Zoom in={isVisible}>
        <Fab
          color="secondary"
          size="small"
          onClick={scrollToTop}
          aria-label="scroll back to top"
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 100,
            backgroundColor: "#00a3ff",
            "&:hover": {
              backgroundColor: "#a00d56",
            },
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Zoom>
    </>
  );
};

export default OrthopedicPage;
