import {
  AppBar,
  Toolbar,
  Button,
  Box,
  useTheme,
  IconButton,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";

const navItems = [
  { label: "Home", href: "#hero" },
  { label: "About Us", href: "#about-us" },
  { label: "Services", href: "#services" },
  { label: "Patient Info", href: "#patient-info" },
  { label: "Contact Us", href: "#contact-us" },
  { label: "Testimonials", href: "#testimonials" },
];

const Navbar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detect if screen size is small

  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer open/close

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        backgroundColor: theme.palette.background.paper,
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navItems.map((item) => (
          <ListItem button key={item.label} component="a" href={item.href}>
            <ListItemText
              primary={item.label}
              sx={{
                textTransform: "none",
                color: theme.palette.accent.main,
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        "& .MuiToolbar-root": { px: 0, py: 2 },
        backgroundColor: "transparent",
        width: "100%",
        // position: "absolute",
        // top: 0,
        boxShadow: "none",
        zIndex: 9,
        px: theme.spacing(8),
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: "transparent",
          justifyContent: "space-between",
          px: 0, // Directly target the padding on the Toolbar itself
        }}
      >
        {/* Logo aligned to the left */}
        <img src="/physio-logo.png" width={80} height={80} alt="Logo" />

        {/* Conditionally render the nav items or the Menu icon based on screen size */}
        {!isMobile ? (
          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-start" }}>
            {navItems.map((item) => (
              <Button
                key={item.label}
                color="inherit"
                href={item.href}
                sx={{
                  textTransform: "none",
                  color: theme.palette.accent.main,
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        ) : (
          <>
            {/* Menu icon for small screens */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ color: theme.palette.accent.main }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>

            {/* Drawer for mobile navigation */}
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {drawerContent}
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
