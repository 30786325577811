import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  IconButton,
  useTheme,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from '@mui/icons-material/Google';
import dentalTheme from "../../../theme1";

// Updated testimonials with feedback from Google reviews
const testimonials = [
  {
    name: "Kerrol Simmonds",
    handle: "Google Review", // Platform as Google
    feedback:
      "The environment was clean and welcoming, and the customer service was superb. My dad was well taken care of, and I was impressed with the team's care and attention. Dr. Ranger provided the gentlest teeth cleaning for my sensitive teeth. Highly recommend!",
    avatar: "https://lh7-rt.googleusercontent.com/docsz/AD_4nXcJnvhuu6T-HVU94VBIgvND1vevO2LlJ2pgLKy6ddYyzs-SHd6w3cVnd8P4p6hBito75otO6IEEi9y9b4KDXbLQSZVQQMkmcUuVThJAig-ersbChQ1Hv07WaOXPdiwiJxeiX72zVqtt80CtzfycD_1oj7M=s800?key=1aArNykjyv6gbodmekoKig",
    platform: "google", // Platform for the testimonial
    link: "https://www.google.com/maps/contrib/102483986328734235951/reviews/@18.4310413,-77.1848701,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-GB&entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D"
  },
  {
    name: "Yolly B",
    handle: "Google Review",
    feedback:
      "Amoya’s excellent communication and warm smile made me switch dentists. Dr. Ranger was thorough and gentle, and the office was very clean with soft music. I was comfortable, and I would definitely recommend this place!",
    avatar: "https://lh7-rt.googleusercontent.com/docsz/AD_4nXfUDXq6_eiiVn1TPoR5gnme0Xu3tvOdBW0RTZW0dQiCUGYTbLIZKyh84OPMCdF6l5LJk-o7tcG05yJ3urZTkAE5CKU3v7x9Aky663rKc35lgEr785kSyQXtdLYhTCxAzzwC3THyLv6WfZqWvkXj6EIWxm1g=s800?key=1aArNykjyv6gbodmekoKig",
    platform: "google",
    link: "https://www.google.com/maps/contrib/114428007218326297344/reviews/@18.2507054,-77.5775888,9z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-GB&entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D"
  },
  {
    name: "Craig Lamm",
    handle: "Google Review",
    feedback:
      "I visited for a free cleaning and examination and had a great experience. The staff was hospitable, and I learned a lot about dental hygiene. I will definitely be coming back for more treatments!",
    avatar: "https://lh7-rt.googleusercontent.com/docsz/AD_4nXdfOL4YMeNjm_OZ1Gno5rvUUw6_Kn4xUssiu2Hebt2iqQjHbG4qgd720bHWtPKjqAh5cyVBcP9uoaooDKLEeTo9d2jukV8DtiMyddvg3CSimhLNXyG7t6QQafDkN7k0b69_82-KV4lX5Je26wpe5UAdJG0Z=s800?key=1aArNykjyv6gbodmekoKig",
    platform: "google",
    link: "https://www.google.com/maps/contrib/110600791748950850987/reviews/@18.4444474,-77.6677999,10z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-GB&entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D"
  },
];

// Helper function to get the right icon based on the platform
const getPlatformIcon = (platform: string) => {
  switch (platform) {
    case "whatsapp":
      return <WhatsAppIcon fontSize="medium" />;
    case "email":
      return <EmailIcon fontSize="medium" />;
    case "instagram":
      return <InstagramIcon fontSize="medium" />;
    case "twitter":
      return <TwitterIcon fontSize="medium" />;
    case "facebook":
      return <FacebookIcon fontSize="medium" />;
    case "google":
      return <GoogleIcon fontSize="medium"/>
    default:
      return null;
  }
};

function Testimonials() {
  const theme = useTheme();
  return (
    <Box
      id="testimonials"
      sx={{ py: 8, backgroundColor: "background.default" }}
    >
      <Container>
        {/* Title Section */}
        <Typography
          variant="h4"
          component="h4"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "Avenir Next LT Pro",
            fontWeight: "bold",
            color: dentalTheme.palette.primary.main,
            mb: theme.spacing(4),
          }}
        >
          Public Cheers for Us!
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 4, textAlign: "center", color: "text.secondary" }}
        >
          Find out how our users are spreading the word!
        </Typography>

        {/* Testimonials Grid */}
        <Grid container spacing={4} alignItems={"stretch"}>
          {testimonials.map((testimonial) => (
            <Grid item xs={12} sm={6} md={4} key={testimonial.handle} display={"flex"}>
              <Card
                sx={{
                  p: 2,
                  borderRadius: 8,
                  boxShadow: "none",
                  backgroundColor: "background.paper",
                }}
              >
                {/* Avatar and Header Section */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      src={testimonial.avatar}
                      alt={testimonial.name}
                      sx={{ width: 56, height: 56, mb: 2 }}
                    />
                    <Box sx={{ ml: 2 }}>
                      <Typography variant="h6" component="h6">
                        {testimonial.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.handle}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Platform Icon */}
                  <IconButton size="small" href={testimonial.link} target="_blank">
                    {getPlatformIcon(testimonial.platform)}
                  </IconButton>
                </Box>

                {/* Testimonial Content */}
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    textAlign: "left",
                    p: 0,
                  }}
                >
                  <Typography variant="body2" color="text.primary">
                    {testimonial.feedback}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Testimonials;
