import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { Button } from "@mui/material";

export default function CustomizedInputBase({
  subject,
  setSubject,
  onClick,
}: {
  subject: string;
  setSubject: (val: string) => void;
  onClick: () => void;
}) {
  return (
    <Paper
      component="form"
      sx={{
        p: 0,
        py: "1px",
        pr: "1px",
        display: "flex",
        alignItems: "space-between",
        width: "100%",
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "2px solid white",
        borderRadius: 8
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, color: "white" }}
        placeholder="Enter subject"
        inputProps={{ "aria-label": "search google maps" }}
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <Button
        variant="contained"
        onClick={onClick}
        sx={{
          borderRadius: 8,
          backgroundColor: "white",
          color: "black",
          fontWeight: "bold",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        Enquire
      </Button>
    </Paper>
  );
}
