import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Stack,
  IconButton,
  AppBar,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { HomePageButtons } from "../constants";
import {
  Instagram,
  // Twitter,
  // LinkedIn,
  // ArrowForward,
  Menu as MenuIcon,
  Close as CloseIcon,
  Facebook,
} from "@mui/icons-material";
import ScreenWrapper from "../components/ScreenWrapper";

const HomePage: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer open/close

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <ScreenWrapper>
      {/* Navbar */}
      <AppBar
        position="static"
        sx={{ backgroundColor: theme.palette.background.paper }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Logo on the left */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src="/main-logo.png" alt="Logo" style={{ height: "50px" }} />
          </Box>

          {/* Links to the three offices */}
          {isSmallScreen ? (
            // Hamburger Menu for small screens
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: { xs: "block", md: "none" } }}
              onClick={toggleDrawer}
            >
              <MenuIcon sx={{ color: theme.palette.accent.main }} />
            </IconButton>
          ) : (
            // Desktop Navbar Links
            <Box>
              {HomePageButtons.map((button, index) => (
                <Button
                  key={index}
                  component={Link}
                  to={button.to}
                  sx={{
                    marginLeft: theme.spacing(2),
                    backgroundColor: "transparent",
                    color: theme.palette.accent.main,
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {button.label}
                </Button>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for Small Screens */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box
          sx={{
            width: 250,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2),
          }}
        >
          {/* Close Icon */}
          <IconButton
            sx={{
              position: "absolute",
              top: theme.spacing(2),
              right: theme.spacing(2),
            }}
            onClick={toggleDrawer}
          >
            <CloseIcon />
          </IconButton>

          {/* Drawer Menu Links */}
          <List>
            {HomePageButtons.map((button, index) => (
              <ListItem
                button
                key={index}
                component={Link}
                to={button.to}
                onClick={toggleDrawer}
              >
                <ListItemText primary={button.label} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box
        sx={{
          position: "relative",
          minHeight: "100vh",
          width: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: isSmallScreen ? "center" : "space-between",
          px: isSmallScreen ? theme.spacing(5) : theme.spacing(15),
          boxSizing: "border-box",
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(2,73,117,1) 55%, rgba(0,212,255,1) 100%)",
          pt: theme.spacing(4), // Add top padding to avoid overlap with the navbar
        }}
      >
        {/* Main Content Left Side */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: { xs: "center", md: "left" },
            color: "white",
            zIndex: 1,
            mt: theme.spacing(2),
          }}
        >
          <Typography
            component="p"
            gutterBottom
            color="white"
            sx={{
              fontSize: { xs: "1.5rem", sm: "1.5rem", md: "1.5rem" },
              textAlign: { xs: "center", md: "left" },
              fontFamily: "Roboto",
              fontWeight: "lighter",
              fontStyle: "italic",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.3)",
            }}
          >
            ~ Your Health, Our Priority.
          </Typography>
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
              textAlign: { xs: "center", md: "left" },
              fontFamily: "Avenir Next LT Pro",
              fontWeight: "bold",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.3)",
              color: "white",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                textAlign: { xs: "center", md: "left" },
                fontFamily: "Avenir Next LT Pro",
                fontWeight: "bold",
                color: "#E3B505",
                display: "inline-block",
              }}
            >
              {" "}
              Drax Hall
            </Typography>{" "}
            Health Group
          </Typography>

          <Box
            component="ol"
            sx={{
              listStyle: "none",
              padding: 0,
              marginLeft: 0,
              marginTop: theme.spacing(1),
              textAlign: { xs: "center", md: "auto" },
            }}
          >
            {HomePageButtons.map((button, index) => (
              <Box
                key={index}
                component="li"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: theme.spacing(1),
                }}
              >
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    backgroundColor: "#E3B505",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "rgba(2,0,36,1)",
                    fontWeight: "bold",
                    marginRight: theme.spacing(2),
                  }}
                >
                  {index + 1}
                </Box>
                <Typography variant="body1" sx={{ color: "white" }}>
                  {button.label}
                </Typography>
              </Box>
            ))}
          </Box>

          <Typography
            variant="h5"
            sx={{
              fontSize: { xs: "1.5rem", sm: "1.5rem", md: "1.5rem" },
              textAlign: { xs: "center", md: "left" },
              fontFamily: "Roboto",
              fontWeight: "normal",
              color: "white",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.3)",
            }}
          >
            Choose an office to explore
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: { xs: "center", md: "flex-start" },
              width: "100%",
              gap: { xs: 2, sm: 3, md: 4 },
              marginY: 2,
            }}
          >
            {HomePageButtons.map((button, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  width: { xs: "100%", md: 150 },
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", md: 150 },
                    height: { xs: 300, md: 150 },
                  }}
                >
                  <Button
                    component={Link}
                    to={button.to}
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "100%",
                      padding: 0,
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      borderRadius: 2,
                      boxShadow: "none",
                      transition: "transform 0.3s ease",
                      "&:hover": {
                        boxShadow: "none",
                      },
                      "& img": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition: "transform 0.3s ease",
                      },
                      "&:hover img": {
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    {button.icon}
                  </Button>
                </Box>
                <Button
                  component={Link}
                  to={button.to}
                  variant="contained"
                  sx={{
                    width: 150,
                    color: "white",
                    backgroundColor: theme.palette.accent.main,
                    borderRadius: 2,
                    border: "1px solid #E3B505",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: theme.palette.accent.main,
                      color: "white",
                    },
                    mt: theme.spacing(2),
                    px: 0,
                  }}
                >
                  {button.to === "/medical"
                    ? "Medical & Surgical"
                    : button.to === "/dental"
                    ? "Family Dental"
                    : "Physio & Rehab"}
                </Button>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Main Content Right Side */}
        {!isSmallScreen && (
          <img
            src="/main-doc.PNG"
            style={{
              position: "absolute",
              bottom: 0,
              right: "20%",
              width: "28%",
            }}
            alt="Doctor"
          />
        )}

        {/* Social Media Icons */}
        <Stack
          direction={isSmallScreen ? "row" : "column"}
          spacing={2}
          sx={{
            marginTop: "auto",
            alignItems: isSmallScreen ? "center" : "flex-end",
            justifyContent: "center",
            width: isSmallScreen ? "100%" : "50%",
            my: theme.spacing(5),
          }}
        >
          <IconButton
            component="a"
            href="https://www.instagram.com/draxhall_medsurge/"
            target="_blank"
            aria-label="Instagram"
            sx={{
              color: "rgb(42,79,109)",
              fontSize: "2rem",
              width: 56,
              height: 56,
              borderRadius: "50%",
              backgroundColor: "rgba(255,255,255,0.2)",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.4)",
              },
            }}
          >
            <Instagram sx={{ color: "white" }} />
          </IconButton>
          {/* <IconButton
            component="a"
            href="https://www.twitter.com"
            target="_blank"
            aria-label="Twitter"
            sx={{
              color: "rgb(42,79,109)",
              fontSize: "2rem",
              width: 56,
              height: 56,
              borderRadius: "50%",
              backgroundColor: "rgba(255,255,255,0.2)",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.4)",
              },
            }}
          >
            <Twitter sx={{ color: "white" }} />
          </IconButton> */}
          <IconButton
            component="a"
            href="https://facebook.com/draxhallmedicalandsurgical"
            target="_blank"
            aria-label="LinkedIn"
            sx={{
              color: "rgb(42,79,109)",
              fontSize: "2rem",
              width: 56,
              height: 56,
              borderRadius: "50%",
              backgroundColor: "rgba(255,255,255,0.2)",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.4)",
              },
            }}
          >
            <Facebook sx={{color: "white"}} />
          </IconButton>
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          py: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="body1">
          © {new Date().getFullYear()} Drax Hall Health Group
        </Typography>
        {/* Social Media Icons */}
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            // marginTop: "auto",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <IconButton
            component="a"
            href="https://www.instagram.com/draxhall_medsurge/"
            target="_blank"
            aria-label="Instagram"
            sx={{
              color: "rgb(42,79,109)",
              fontSize: "2rem",
              width: 56,
              height: 56,
              borderRadius: "50%",
              backgroundColor: "rgba(255,255,255,0.2)",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.4)",
              },
            }}
          >
            <Instagram color="inherit" />
          </IconButton>
          {/* <IconButton
            component="a"
            href="https://www.twitter.com"
            target="_blank"
            aria-label="Twitter"
            sx={{
              color: "rgb(42,79,109)",
              fontSize: "2rem",
              width: 56,
              height: 56,
              borderRadius: "50%",
              backgroundColor: "rgba(255,255,255,0.2)",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.4)",
              },
            }}
          >
            <Twitter color="inherit" />
          </IconButton> */}
          <IconButton
            component="a"
            href="https://facebook.com/draxhallmedicalandsurgical"
            target="_blank"
            aria-label="LinkedIn"
            sx={{
              color: "rgb(42,79,109)",
              fontSize: "2rem",
              width: 56,
              height: 56,
              borderRadius: "50%",
              backgroundColor: "rgba(255,255,255,0.2)",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.4)",
              },
            }}
          >
            <Facebook color="inherit" />
          </IconButton>
        </Stack>
      </Box>
    </ScreenWrapper>
  );
};

export default HomePage;
