import React from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";

const PreparationSection: React.FC = () => {
  return (
    <Paper
      elevation={3}
      sx={{ p: 4, boxShadow: 2, borderRadius: 2, backgroundColor: "#fff" }}
      id="preparation"
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ textAlign: "center", mb: 4, color: "GrayText" }}
      >
        Proper preparation is essential for the accuracy and safety of the
        procedures.
      </Typography>

      <Grid container spacing={6} justifyContent="center">
        {/* Consultation Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 3, textAlign: "center" }}>
            <img
              src="https://www.virtualdoctors.ng/vmedia/2023/08/istockphoto-1400053534-612x612-1.jpg" // Replace with your actual image path
              alt="Consultation"
              style={{
                width: "100%",
                height: "300px", // Set the same height for all images
                objectFit: "cover", // Ensure the image covers the area without distortion
                borderRadius: "8px",
              }}
            />
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "left", mt: 2 }}
            >
              Consultation
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              Before the procedure, your doctor will discuss your medical
              history, current medications, and any allergies. Make sure to
              inform them about any blood thinners or other medications you are
              taking.
            </Typography>
          </Box>
        </Grid>

        {/* Dietary Restrictions Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 3, textAlign: "center" }}>
            <img
              src="https://medicircle.in/uploads/2022/september2022/liquid-diet-benefits.jpg" // Replace with your actual image path
              alt="Dietary Restrictions"
              style={{
                width: "100%",
                height: "300px", // Set the same height for all images
                objectFit: "cover", // Ensure the image covers the area without distortion
                borderRadius: "8px",
              }}
            />
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "left", mt: 2 }}
            >
              Dietary Restrictions
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              You will typically be advised to follow a special diet in the days
              leading up to the procedure. This often includes a clear liquid
              diet for 24 hours prior to the procedure.
            </Typography>
          </Box>
        </Grid>

        {/* Bowel Preparation Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 3, textAlign: "center" }}>
            <img
              src="https://www.verywellhealth.com/thmb/3S7DzH3TuD_XN-w-Viep6ZfXJXc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/laxative-GettyImages-1480595414-70f8d27b425a4e1a99add954c2b6ef79.jpg" // Replace with your actual image path
              alt="Bowel Preparation"
              style={{
                width: "100%",
                height: "300px", // Set the same height for all images
                objectFit: "cover", // Ensure the image covers the area without distortion
                borderRadius: "8px",
              }}
            />
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "left", mt: 2 }}
            >
              Bowel Preparation (For Colonoscopy)
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              For colonoscopy, you will need to clean out your colon. This is
              usually achieved through a prescribed laxative or cleansing
              solution, which you will take the night before the procedure. It’s
              crucial to follow these instructions carefully for optimal
              results.
            </Typography>
          </Box>
        </Grid>

        {/* Arranging Transportation Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 3, textAlign: "center" }}>
            <img
              src="https://www.ucsfhealth.org/-/media/project/ucsf/ucsf-health/education/hero/medi-cal-free-transportation-ucsfhealth-2x.jpg?rev=da7fab9f217b4fe29adcd4ef10fb00e7" // Replace with your actual image path
              alt="Arranging Transportation"
              style={{
                width: "100%",
                height: "300px", // Set the same height for all images
                objectFit: "cover", // Ensure the image covers the area without distortion
                borderRadius: "8px",
              }}
            />
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "left", mt: 2 }}
            >
              Arranging Transportation
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              Since sedation is often used during these procedures, you will
              need someone to drive you home afterward.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PreparationSection;
