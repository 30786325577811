import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  useTheme,
} from "@mui/material";
import SpaIcon from "@mui/icons-material/Spa";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import HealingIcon from "@mui/icons-material/Healing";

const FacilitiesAndMethods: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        py: 8,
        textAlign: "center",
        backgroundColor: "#f9f9f9",
        px: { xs: theme.spacing(5), md: theme.spacing(15) },
      }}
    >
      {/* Clinic Facilities Section */}
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            mb: 4,
          }}
        >
          Clinic Facilities
        </Typography>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://www.gulfphysio.com/wp-content/uploads/2022/08/Physical-therapy-clinic-1.png" // Replace with an actual image path
              alt="Clinic Facilities"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: 4,
                boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              sx={{
                lineHeight: 1.8,
                color: "text.secondary",
                textAlign: "left",
              }}
            >
              Our clinic is designed with your comfort and recovery in mind. We
              offer modern treatment rooms equipped with the latest
              physiotherapy and rehabilitation technology, including ultrasound,
              electrotherapy devices, and a fully equipped gym for therapeutic
              exercises. Our massage and spa rooms provide a peaceful
              environment for relaxation and recovery, supporting a holistic
              approach to your wellness journey.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Treatment Methods and Modalities Section */}
      <Box>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            mb: 4,
          }}
        >
          Treatment Methods and Modalities
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {[
            {
              title: "Manual Therapy",
              description:
                "Hands-on techniques to reduce pain, improve range of motion, and restore function.",
              icon: <HealingIcon fontSize="large" />,
            },
            {
              title: "Exercise Therapy",
              description:
                "Personalized exercise programs to strengthen muscles, improve flexibility, and enhance mobility.",
              icon: <FitnessCenterIcon fontSize="large" />,
            },
            {
              title: "Clinical Massage Therapy",
              description:
                "Targeted massage techniques to address musculoskeletal issues and improve physical health.",
              icon: <SpaIcon fontSize="large" />,
            },
            {
              title: "Patient Education",
              description:
                "Empowering patients with knowledge about their condition and recovery process.",
              icon: <HealingIcon fontSize="large" />,
            },
          ].map((method, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  textAlign: "center",
                  boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
                  padding: 4,
                  borderRadius: 4,
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "primary.main",
                  }}
                >
                  {method.icon}
                </Box>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                    {method.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {method.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default FacilitiesAndMethods;
