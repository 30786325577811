import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

interface Specialty {
  name: string;
  description: string;
  image: string;
}

const specialties: Specialty[] = [
  {
    name: "Arthritis",
    description:
      "We provide management strategies to relieve pain and improve joint function for various types of arthritis.",
    image:
      "https://ssbhealthcare.com/wp-content/uploads/2023/02/Untitled-1.png", // Replace with real image URL
  },
  {
    name: "Elbow",
    description:
      "Our specialists treat elbow injuries like fractures and tendonitis, offering both surgical and non-surgical options for recovery.",
    image:
      "https://media.post.rvohealth.io/wp-content/uploads/2022/02/man_in_physical_therapy_for_tennis_elbow-1200x628-facebook-1200x628.jpg", // Replace with real image URL
  },
  {
    name: "Foot & Ankle",
    description:
      "We address issues such as sprains, fractures, and chronic conditions like plantar fasciitis, ensuring comprehensive care for your lower extremities.",
    image:
      "https://siortho.com/wp-content/uploads/2022/10/shutterstock_1016392276.jpg", // Replace with real image URL
  },
  {
    name: "General Orthopedics",
    description:
      "Our general orthopedic care encompasses a wide range of musculoskeletal issues, providing thorough evaluation and treatment.",
    image:
      "https://drive.tiny.cloud/1/fma0ckgi8l71unwv372o9bkc28qzcn65s72m80cndsw3awok/d0a61229-ec76-4c11-b17f-865290fb3228", // Replace with real image URL
  },
  {
    name: "Hand & Wrist",
    description:
      "We focus on conditions affecting the hand and wrist, including carpal tunnel syndrome, trigger finger, ganglion cysts, and fractures, with tailored treatment plans.",
    image:
      "https://www.keithraskinmd.com/wp-content/uploads/2020/10/Carpal-Tunnel-Syndrome.jpg", // Replace with real image URL
  },
  {
    name: "Hip & Thigh",
    description:
      "Our team treats hip and thigh conditions such as arthritis and bursitis, helping you regain mobility and reduce pain.",
    image:
      "https://pttimewithtim.com/wp-content/uploads/2021/04/Hip-Bursitis-Trochanteric-Bursitis-Pain.jpg", // Replace with real image URL
  },
  {
    name: "Shoulder",
    description:
      "We treat various shoulder conditions, including rotator cuff tears and arthritis, using both surgical and non-surgical methods.",
    image:
      "https://www.bone-joint.com/wp-content/uploads/sites/393/2024/03/Rotator-Cuff-Tear.jpg.webp", // Replace with real image URL
  },
  {
    name: "Knee & Leg",
    description:
      "We address various knee and leg issues, from tendon and ligament injuries and arthritis to sports-related injuries, providing personalized treatment plans.",
    image:
      "https://images.everydayhealth.com/images/pain-management/knee-pain/knee-pain-symptoms-and-possible-causes-1440x810.jpg", // Replace with real image URL
  },
];

const SpecialtyList: React.FC = () => {
  const [activeSpecialty, setActiveSpecialty] = useState<Specialty | null>(
    specialties[0]
  );

  const handleSpecialtyClick = (specialty: Specialty) => {
    setActiveSpecialty(specialty);
  };

  return (
    <Box id="specialty-list" sx={{ p: { xs: 5, md: 12 } }}>
      <Grid container spacing={4}>
        {/* Left Side: Title and List of Specialties */}
        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom>
            Care options by specialty
          </Typography>
          <Typography variant="body1" paragraph>
            We make it easy to get on the path to recovery with unparalleled,
            specialized care for your pain or injury—from diagnosis and
            treatment to pain management and rehabilitation.
          </Typography>
          <Box>
            <ul
              style={{
                columnCount: 2,
                columnGap: "16px",
                listStyle: "none",
                padding: 0,
                margin: 0,
              }}
            >
              {specialties.map((specialty) => (
                <li
                  key={specialty.name}
                  style={{ marginBottom: "16px", cursor: "pointer" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      color: "black",
                      textAlign: "left"
                    }}
                    onClick={() => handleSpecialtyClick(specialty)}
                  >
                    <ChevronRight
                      fontSize="small"
                      style={{ marginRight: "8px" }}
                    />
                    {specialty.name}
                  </Button>
                </li>
              ))}
            </ul>
          </Box>
        </Grid>

        {/* Right Side: Display Active Specialty Description */}
        <Grid item xs={12} md={6}>
          {activeSpecialty ? (
            <Card
              elevation={0}
              sx={{
                maxWidth: "100%",
                backgroundColor: "transparent",
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={activeSpecialty.image}
                alt={activeSpecialty.name}
              />
              <CardContent sx={{ paddingX: 0 }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    color: "black",
                    paddingY: "8px",
                  }}
                >
                  {activeSpecialty.name}
                </Typography>
                <Typography variant="body1" sx={{ color: "#333" }}>
                  {activeSpecialty.description}
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <Typography
              variant="body1"
              style={{ textAlign: "center", color: "#666" }}
            >
              Click on a specialty to view more information.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SpecialtyList;
