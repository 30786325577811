import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { careOptionsData } from "./careOptionsData";

const CareOptionsTabs: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleListItemClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <Box
      id="care-options"
      sx={{  p: { xs: 5, md: 12 }, backgroundColor: "white" }}
    >
      <Grid container spacing={4}>
        {/* Left: Vertical List */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            borderRight: { md: "1px solid #e0e0e0" },
            paddingRight: { md: 2 },
          }}
        >
          <List>
            {careOptionsData.map((option, index) => (
              <ListItemButton
                key={option.title}
                selected={activeIndex === index}
                onClick={() => handleListItemClick(index)}
                sx={{
                  backgroundColor:
                    activeIndex === index ? "#f8f8f8" : "transparent",
                  borderLeft:
                    activeIndex === index
                      ? "4px solid #0073e6"
                      : "4px solid transparent",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
              >
                <ListItemText
                  primary={option.title}
                  primaryTypographyProps={{
                    fontWeight: activeIndex === index ? "bold" : "normal",
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </Grid>

        {/* Right: Content Area */}
        <Grid item xs={12} md={activeIndex === 0 ? 8 : 4}>
          <Box>
            <Typography variant="h4" gutterBottom>
              {careOptionsData[activeIndex].subtitle}
            </Typography>
            <Typography variant="body1" paragraph>
              {careOptionsData[activeIndex].description}
            </Typography>
            <Box
              sx={{
                "& ul": {
                  listStyle: "none",
                  padding: 0,
                  "& li": {
                    marginBottom: "8px",
                  },
                },
              }}
            >
              <ul>
                {careOptionsData[activeIndex].treatments.map((treatment) => (
                  <li key={treatment.name}>
                    <p>{treatment.name}</p>
                  </li>
                ))}
              </ul>
            </Box>
            {activeIndex === 0 ? (
              <Box mt={4}>
                <img
                  src={careOptionsData[activeIndex].image}
                  alt={careOptionsData[activeIndex].altText}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            ) : null}
          </Box>
        </Grid>

        {activeIndex > 0 ? (
          <Grid item xs={12} md={4}>
            <Box mt={4}>
              <img
                src={careOptionsData[activeIndex].image}
                alt={careOptionsData[activeIndex].altText}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default CareOptionsTabs;
