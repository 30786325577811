import React from 'react';
import { Box, Fade } from '@mui/material';

const ScreenWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Fade in={true} timeout={1000}>
      <Box sx={{ height: '100%', width: '100%' }}>
        {children}
      </Box>
    </Fade>
  );
};

export default ScreenWrapper;
