import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import StaffCard from "../../../components/StaffCard";


const AboutUs: React.FC = () => {
  const staff = [
    {
      name: "Dr. Bruce Blagrove",
      title: "Orthopaedic Surgeon",
      image: `/images/medical/b-blagrove.jpg`,
      bio: "Dr. Bruce E. Blagrove is a General Orthopaedic & Trauma Surgeon at Drax Hall Medical & Surgical Ltd. and St. Ann’s Bay Regional Hospital. He completed his Doctor of Medicine (DM) in Orthopaedic Surgery at the University of the West Indies (UWI) in 2020, after earning his MBBS and B. MSc. degrees there. Dr. Blagrove specializes in trauma, deformity correction, and hip and knee orthoplasty. He is a member of several professional organizations, including the Jamaican Orthopaedic Association, the Medical Association of Jamaica, and the American Association of Orthopaedic Surgeons.",
      expertise_areas: ["Trauma", "Deformity Correction", "Hip and Knee Orthoplasty"],
      qualifications: ["B.Sc.", "BMSc.", "MBBS", "DM"],
    },
    {
      name: "Dr. Jehovan Fairclough",
      title: "Consultant Gastroenterologist and Advanced Therapeutic Endoscopist",
      image: "/images/medical/fairclough.jpg",
      bio: "Dr. Jehovan Fairclough earned his MBBS and Doctor of Medicine (DM) in Internal Medicine from the University of the West Indies, followed by advanced fellowships in Gastroenterology and Therapeutic Endoscopy at the University of Ottawa. As the only fellowship-trained Gastroenterologist in the Caribbean specializing in Endoscopic Ultrasound (EUS), Dr. Fairclough is board-certified and a Fellow of both the European Board of Gastroenterology and the Royal College of Physicians (UK).",
      expertise_areas: ["Endoscopic Ultrasound (EUS)", "Colon Cancer Screening", "Advanced Endoscopy"],
      qualifications: ["MBBS", "DM", "Fellowship in Gastroenterology"],
    },
    {
      name: "Dr. Sandralee McFarlane",
      title: "General Practitioner | Primary Care Physician",
      image: "/images/medical/mcfarlane.jpg",
      bio: "Dr. Sandralee McFarlane is a graduate of the University of the West Indies with an MBBS degree. She has extensive experience in primary care, including the management of hypertension and diabetes, as well as in adult psychiatry. Dr. McFarlane is passionate about patient education and empowerment, and she takes a holistic approach to health, focusing on the overall well-being of her patients.",
      expertise_areas: ["Primary Care", "Chronic Conditions", "Adult Psychiatry"],
      qualifications: ["MBBS"],
    },
    // {
    //   name: "Dr. Chrys-Ann McLaren",
    //   title: "General Practitioner",
    //   image: "",
    //   bio: "Dr. Chrys-Ann McLaren is a passionate and dedicated medical professional committed to providing exceptional healthcare. She graduated with a Bachelor of Surgery and Bachelor of Medicine from the University of the West Indies, Mona. Dr. McLaren brings a wealth of knowledge and expertise to her practice.",
    //   expertise_areas: ["General Medicine", "Patient Care", "Health Education"],
    //   qualifications: ["MBBS"],
    // },
    {
      name: "Dr. Davinia Passley",
      title: "Ear, Nose, and Throat Specialist",
      image: "",
      bio: "Dr. Davinia Passley is a distinguished ear, nose, and throat consultant specializing in otolaryngology. She is recognized for her expertise in diagnosing and treating conditions affecting the ear, nose, sinuses, larynx, mouth, and throat. Her areas of expertise include managing allergies, sinusitis, hearing loss, and voice disorders.",
      expertise_areas: ["Allergies", "Sinusitis", "Hearing Loss", "Voice Disorders"],
      qualifications: ["MBBS", "MD"],
    },
    {
      name: "Dr. Patrice Pinkney",
      title: "General and Thoracic Surgeon",
      image: "/images/medical/pinkney.jpg",
      bio: "Dr. Patrice Pinkney is a Consultant Thoracic Surgeon at Drax Hall Medical & Surgical and the University Hospital of the West Indies. She specializes in thoracic oncology, minimally invasive surgery, and advanced treatment for esophageal and lung cancer.",
      expertise_areas: ["Thoracic Surgery", "Minimally Invasive Surgery", "Lung Cancer Management"],
      qualifications: ["MBBS", "Fellowship in Thoracic Surgery"],
    },
    {
      name: "Dr. Lisa Chin-Harty",
      title: "Consultant Physician in Diabetes and Endocrinology",
      image: `/images/medical/harty.png`,
      bio: "Dr. Lisa Chin-Harty serves as a Consultant Physician in Diabetes and Endocrinology at Drax Hall Medical and Surgical, as well as an Associate Lecturer at the University of the West Indies. She specializes in diabetes technology, thyroid disorders, and hormone replacement therapy.",
      expertise_areas: ["Diabetes Technology", "Thyroid Disorders", "Hormone Replacement Therapy"],
      qualifications: ["MBBS", "DM", "MRCP (UK)"],
    },
    {
      name: "Dr. Calvin Young",
      title: "Mental Health Clinician",
      image:  `/images/medical/young.png`,
      bio: "Dr. Calvin Young is a highly skilled mental health clinician with extensive experience in counseling and forensic assessment. He specializes in Dialectical Behavior Therapy, substance abuse treatment, and forensic psychology.",
      expertise_areas: ["Dialectical Behavior Therapy", "Substance Abuse Treatment", "Forensic Assessment"],
      qualifications: ["PhD", "LCPC", "SAP"],
    },
    {
      name: "Dr. Chantel Clarke",
      title: "Consultant Obstetrician and Gynaecologist",
      image:  `/images/medical/clarke.jpg`,
      bio: "Dr. Chantel Clarke is a dedicated Consultant Obstetrician and Gynaecologist specializing in minimally invasive surgeries, fibroid removal, and maternal health. She actively engages in women's health initiatives and education.",
      expertise_areas: ["Minimally Invasive Surgery", "Maternal Health", "Obstetrics"],
      qualifications: ["MBBS", "DM OBGYN"],
    },
    {
      name: "Mrs. Patricia Murray",
      title: "Registered Dietitian & Nutritionist",
      image:  `/images/medical/murray.png`,
      bio: "Mrs. Patricia Murray brings over 40 years of experience in dietetics and nutrition. She specializes in managing non-communicable diseases, gestational diabetes, and feeding challenges.",
      expertise_areas: ["Nutrition", "Gestational Diabetes", "Non-Communicable Diseases"],
      qualifications: ["B.Sc.", "M.Sc."],
    },
    {
      name: "Dr. Latoya Reid",
      title: "Haematology/Medical Oncology Specialist",
      image: "/images/medical/reid.jpg",
      bio: "Dr. Latoya Reid is a dedicated physician specializing in Haematology and Medical Oncology. She actively contributes to research and is committed to delivering compassionate and exceptional care to her patients.",
      expertise_areas: ["Haematology", "Oncology", "Patient Care"],
      qualifications: ["MBBS", "DM"],
    },
    {
      name: "Dr. Nardia Hamilton-Blagrove",
      title: "Pediatrician",
      image: "/images/medical/n-blagrove.jpg",
      bio: "Dr. Nardia Hamilton-Blagrove specializes in neonatal care, developmental surveillance, and preventive medicine. She is dedicated to providing comprehensive care to children and empowering families through education.",
      expertise_areas: ["Pediatrics", "Neonatal Care", "Developmental Surveillance"],
      qualifications: ["MD", "DM Pediatrics"],
    },
  ];
  

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detect if screen size is small

  return (
    <Box
      id="about-us"
      sx={{ py: 8, px: isMobile ? theme.spacing(6) : theme.spacing(12) }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Avenir Next LT Pro",
          fontWeight: "bold",
          color: theme.typography.h1.color,
          mb: theme.spacing(10),
        }}
      >
        Meet The Team
      </Typography>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="stretch" // Ensures all grid items stretch to the same height
      >
        {staff.map((person, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{ display: "flex" }}
          >
            <StaffCard staff={person} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AboutUs;
