import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import { Instagram, Twitter, Facebook } from "@mui/icons-material";

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#057291",
        color: "white",
        py: 4,
        mt: 6,
        textAlign: "center",
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
      }}
    >
      {/* Copyright */}
      <Typography variant="body2" gutterBottom sx={{ color: "white" }}>
        © {new Date().getFullYear()} Drax Hall Medical & Surgical.
      </Typography>

      {/* Social Media Links */}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
        <Link href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: "white" }}>
            <Instagram />
          </IconButton>
        </Link>
        <Link href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: "white" }}>
            <Twitter />
          </IconButton>
        </Link>
        <Link href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: "white" }}>
            <Facebook />
          </IconButton>
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
