import React, { useEffect, useState } from "react";
import { Box, Fab, Typography, useTheme, Zoom } from "@mui/material";
import NavigationBar from "./components/Navbar";
import HeroSection from "./components/hero";
import InfoSection from "./components/Info";
import PreparationSection from "./components/Preparation";
import ProcedureSection from "./components/Procedure";
import Footer from "./components/Footer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const EndoscopyColonoscopyPage: React.FC = () => {
  const imageUrls1 = [
    "https://colorectalcancer.org/sites/default/files/styles/fifty_fifty/public/media/images/patient%20looking%20at%20computer.png.webp?itok=mMXXHqyx",
    "https://www.hcahealthcare.co.uk/_next/image?url=https%3A%2F%2Fhcil-p-001.sitecorecontenthub.cloud%2Fapi%2Fpublic%2Fcontent%2Ff66fc6ace4e240dd95a08316ef4fbc46%3Fv%3D24537c83&w=3840&q=75",
  ];

  const imageUrls2 = [
    "https://www.shutterstock.com/image-photo/instruments-diagnostic-endoscopy-closeup-doctor-600nw-2460576327.jpg",
    "https://t3.ftcdn.net/jpg/04/96/66/94/360_F_496669474_cWgyYxYF0uqB6XNejuAhUzjO0GgdYVIP.jpg",
  ];

  const [isVisible, setIsVisible] = useState(false);
  const theme = useTheme();

  // Track scrolling to toggle visibility of the scroll to top button
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: 'url("/images/medical/endoscopy.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed", // Optional, gives a parallax effect
        backgroundRepeat: "no-repeat",
        backgroundColor: "transparent", // Transparent background to allow the gradient overlay
        py: 8,
        px: { xs: 4, sm: 8, md: 10 },
        minHeight: "100vh",
        position: "relative",
      }}
    >
      {/* Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7))",
          zIndex: 1, // Ensures content is on top of the gradient
        }}
      />

      {/* Main Content */}
      <Box sx={{ position: "relative", zIndex: 2 }}>
        {/* Navigation Bar */}
        <NavigationBar />

        <HeroSection />

        <InfoSection
          id="endoscopy"
          title="What is Endoscopy?"
          content="Endoscopy is a broad term that refers to the use of an endoscope—a flexible tube with a camera and light—to visualize the interior of various organs. This procedure can be performed on different parts of the body, including the upper digestive tract (esophagus, stomach, and duodenum)."
          imageUrls={imageUrls1}
          imagePosition="left"
        />

        <InfoSection
          id="colonoscopy"
          title="What is Colonoscopy?"
          content="Colonoscopy, a specific type of endoscopy, focuses on the large intestine (colon) and rectum. During this procedure, a colonoscope, which is a longer tube equipped with a camera, is inserted through the rectum to view the entire colon."
          imageUrls={imageUrls2}
          imagePosition="right"
        />

        <InfoSection
          id="need-for-procedures"
          title="Who Needs Endoscopy or Colonoscopy?"
          content="These procedures are recommended for various reasons, including symptom evaluation, cancer screening, and follow-up care."
          imageUrls={[
            "https://images.ctfassets.net/zlnfaxb2lcqx/Vvfl866qZMn6bX8IMA9ew/49fc135e418ea76939a60f502bbf1cf3/critical-thinking-ck-article1.png",
          ]}
          imagePosition="right"
          additionalContent={[
            {
              title: "Symptom Evaluation",
              description:
                "If you are experiencing symptoms such as unexplained abdominal pain, persistent diarrhea, rectal bleeding, or difficulty swallowing, your doctor may recommend an endoscopy or colonoscopy to determine the underlying cause.",
            },
            {
              title: "Screening",
              description:
                "Colonoscopy is a critical screening tool for colorectal cancer, particularly for individuals aged 45 and older, or those with a family history of colorectal cancer or polyps.",
            },
            {
              title: "Follow-Up",
              description:
                "If you have previously been diagnosed with a GI condition or have undergone treatment, your doctor may recommend these procedures for monitoring and follow-up.",
            },
          ]}
        />

        <Typography
          variant="h4"
          align="center"
          sx={{ my: 10, color: "white", px: { xs: 0, sm: 5, md: 8, lg: 10 } }}
        >
          How to Prepare for Endoscopy & Colonoscopy
        </Typography>
        <PreparationSection />

        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ my: 10, color: "white", px: { xs: 0, sm: 5, md: 8, lg: 10 } }}
        >
          The Procedure
        </Typography>
        <ProcedureSection />

        {/* <CallToAction /> */}

        {/* Footer */}
        <Footer />
      </Box>
      {/* Scroll to Top Button */}
      <Zoom in={isVisible}>
        <Fab
          color="secondary"
          size="small"
          onClick={scrollToTop}
          aria-label="scroll back to top"
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 100,
            backgroundColor: theme.palette.accent.main,
            "&:hover": {
              backgroundColor: "#a00d56",
            },
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Zoom>
    </Box>
  );
};

export default EndoscopyColonoscopyPage;
