import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  ListItemIcon,
  useTheme,
  Grid,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ScheduleIcon from "@mui/icons-material/Schedule";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import dentalTheme from "../../../theme1";

const PatientInfo: React.FC = () => {
  const theme = useTheme();

  // Define a cohesive color for icons and headings
  const primaryColor = dentalTheme.palette.primary.main;
  const accentColor = dentalTheme.palette.secondary.main; // Accent color for highlights

  // Data structure for each section
  const sections = [
    {
      title: "New Patient Instructions",
      icon: <CreditCardIcon sx={{ color: accentColor }} />,
      items: [
        {
          icon: <ScheduleIcon sx={{ color: accentColor }} />,
          title: "Arrive early",
          details:
            "Please arrive at least 5-10 minutes before your appointment time for smooth processing.",
        },
        {
          icon: <CreditCardIcon sx={{ color: accentColor }} />,
          title: "Bring medical and allergy history",
          details:
            "Bring your medical history, including medications, allergies, and any chronic illnesses like Diabetes or Hypertension. Please avoid painkillers if experiencing tooth pain before your appointment.",
        },
      ],
    },
    {
      title: "Insurance Policies & Payment Options",
      icon: <LocalHospitalIcon sx={{ color: accentColor }} />,
      items: [
        {
          icon: <LocalHospitalIcon sx={{ color: accentColor }} />,
          title: "Accepted Insurances",
          details:
            "We accept all major health cards such as Sagicor, Guardian Life, and Canopy. We also accept direct deposit, debit, and credit cards for payment.",
        },
      ],
    },
    {
      title: "Frequently Asked Questions",
      icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
      items: [
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "What should I bring to my first appointment?",
          details:
            "Please bring your medical history, allergy information, and your insurance card. Arriving 5-10 minutes early will help with paperwork.",
        },
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "What are the dos and don’ts after an extraction?",
          details:
            "Do: Rest, drink fluids, and rinse with salt water after 12 hours. Don't: Smoke, drink carbonated beverages, or disturb the clot.",
        },
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "At what age should a child see a dentist?",
          details:
            "A child's first dental visit should occur within six months of their first tooth erupting and no later than their first birthday.",
        },
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "How often should I visit the dentist?",
          details:
            "It is recommended to visit the dentist at least twice a year for regular check-ups, or immediately if you experience dental issues like pain or bleeding gums.",
        },
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "Why do you give fluoride treatment to children?",
          details:
            "Fluoride strengthens teeth and helps prevent decay, especially in children's developing teeth.",
        },
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "What causes cavities?",
          details:
            "Cavities are caused by poor oral hygiene, sugary foods, deep tooth crevices, dry mouth, and over-brushing with hard-bristled toothbrushes.",
        },
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "How effective is teeth whitening, and is it safe?",
          details:
            "Teeth whitening is effective and safe when done correctly, but results are not permanent.",
        },
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "Will my insurance cover this procedure fully or partially?",
          details:
            "Coverage depends on your insurance provider. Please contact us to check if your procedure is covered partially or fully.",
        },
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "How soon can I eat or drink after a cleaning procedure?",
          details:
            "You should wait at least 30 minutes to an hour after a cleaning procedure before eating or drinking to allow your teeth to re-harden.",
        },
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "How often should I floss?",
          details:
            "We recommend flossing at least once a day, but more frequent flossing is encouraged for better oral health.",
        },
        {
          icon: <HelpOutlineIcon sx={{ color: accentColor }} />,
          title: "What causes bad breath?",
          details:
            "Bad breath is often caused by poor oral hygiene, food particles decaying in your mouth, or plaque buildup. Regular brushing, flossing, and mouthwash can help prevent bad breath.",
        },
      ],
    },
  ];

  return (
    <Box
      id="patient-info"
      sx={{
        py: { xs: 6, md: 10 },
        px: { xs: 2, sm: 4, md: 12 },
        backgroundColor: "#fff",
      }}
    >
      {/* Main Heading */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Avenir Next LT Pro",
          fontWeight: "bold",
          color: primaryColor,
          mb: 2,
        }}
      >
        Patient Information
      </Typography>

      {/* Subheading */}
      <Typography
        variant="body1"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Roboto",
          color: "text.secondary",
          maxWidth: 800,
          mx: "auto",
          mb: 6,
        }}
      >
        Your health and comfort are our top priorities. Find all the information
        you need to prepare for your visit and understand our services.
      </Typography>

      {/* Sections Grid */}
      <Grid container spacing={4}>
        {sections.map((section, index) => (
          <Grid item xs={12} sm={index === 2 ? 12 : 6} key={index}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                borderRadius: 2,
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: 6,
                },
              }}
            >
              {/* Section Title */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  {section.icon}
                </ListItemIcon>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: accentColor }}
                >
                  {section.title}
                </Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />

              {/* Accordions */}
              {section.items.map((item, idx) => (
                <Accordion
                  key={idx}
                  sx={{
                    boxShadow: "none",
                    "&:before": {
                      display: "none",
                    },
                    "&:not(:last-child)": {
                      mb: 1,
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: primaryColor }} />}
                    aria-controls={`panel-content-${index}-${idx}`}
                    id={`panel-header-${index}-${idx}`}
                    sx={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: 1,
                      mb: 1,
                      "& .MuiAccordionSummary-content": {
                        marginLeft: 1,
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {item.icon}
                      <Typography
                        variant="subtitle1"
                        sx={{ ml: 1, fontWeight: 500, color: "text.primary" }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="text.secondary">
                      {item.details}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PatientInfo;
