import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  // useTheme,
  Paper,
  // Stack,
} from "@mui/material";
import { Phone, Email, AccessTime } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import dentalTheme from "../../../theme1";

const ContactUs: React.FC = () => {
  // const theme = useTheme();

  // Define a cohesive color for icons and headings
  const primaryColor = dentalTheme.palette.primary.main;
  const accentColor = dentalTheme.palette.secondary.main; // Accent color for highlights

  const handleSubmit = (event: any) => {
    event.preventDefault();
  
    const name = event.target.name.value;
    const email = event.target.email.value;
    const message = event.target.message.value;
  
    // Construct the mailto URL
    const mailtoLink = `mailto:draxhalldental@gmail.com?subject=Contact from ${name}&body=Name: ${name}%0AEmail: ${email}%0AMessage: ${message}`;
  
    // Open the user's default email client with the pre-filled email
    window.location.href = mailtoLink;
  };

  return (
    <Box
      id="contact-us"
      sx={{
        py: { xs: 6, md: 10 },
        px: { xs: 2, sm: 4, md: 12 },
        backgroundColor: "#fff",
      }}
    >
      {/* Main Heading */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Avenir Next LT Pro",
          fontWeight: "bold",
          color: primaryColor,
          mb: 2,
        }}
      >
        Contact Us
      </Typography>

      {/* Subheading */}
      <Typography
        variant="body1"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Roboto",
          color: "text.secondary",
          maxWidth: 800,
          mx: "auto",
          mb: 6,
        }}
      >
        We're here to assist you with any questions or concerns. Get in touch with us today!
      </Typography>

      <Grid container spacing={6} justifyContent="center">
        {/* Contact Form */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={1}
            sx={{
              p: { xs: 3, sm: 4 },
              borderRadius: 2,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ color: accentColor, fontWeight: "bold", mb: 3 }}
            >
              Send Us a Message
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 1,
                  },
                  mb: 4
                }}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                required
                type="email"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 1,
                  },
                  mb: 4
                }}
              />
              <TextField
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 1,
                  },
                  mb: 4
                }}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                sx={{
                  backgroundColor: accentColor,
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#a00d56",
                  },
                  py: 1.5,
                }}
              >
                Send Message
              </Button>
            </form>
          </Paper>
        </Grid>

        {/* Contact Information */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Paper
            elevation={1}
            sx={{
              p: { xs: 3, sm: 4 },
              borderRadius: 2,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ color: accentColor, fontWeight: "bold", mb: 3 }}
            >
              Get in Touch
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Phone sx={{ color: accentColor, mr: 2 }} />
                <Typography variant="body1" sx={{ color: "text.primary" }}>
                  Landline: (876) 972-7329
                  <br />
                  Cell: (876) 457-7981 (WhatsApp)
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Email sx={{ color: accentColor, mr: 2 }} />
                <Typography variant="body1" sx={{ color: "text.primary" }}>
                  <a href="mailto:draxhalldental@gmail.com">draxhalldental@gmail.com</a>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AccessTime sx={{ color: accentColor, mr: 2 }} />
                <Typography variant="body1" sx={{ color: "text.primary" }}>
                  Office Hours: Mon-Fri 9am-5pm, Sat 9am-4pm
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
                <Typography variant="body1" sx={{ color: "text.primary", mb: 2 }}>
                  Address: First floor, Shop #3 Knutsford Express Business Centre
                </Typography>
                {/* Image Placeholder for the building */}
                <Box
                  component="img"
                  src="https://lh3.googleusercontent.com/p/AF1QipPkttHd_AMd88Gan0-uuFdGwrDKDQ1yJgYbB_qv=s680-w680-h510"
                  alt="Office Building"
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: 2,
                    boxShadow: 3,
                  }}
                />
              </Box>
            </Box>

            {/* Social Media Icons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
                gap: 2,
                mt: 2,
              }}
            >
              {/* <IconButton
                href="https://facebook.com"
                target="_blank"
                aria-label="Facebook"
                sx={{
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Facebook sx={{ color: "#3b5998", fontSize: 30 }} />
              </IconButton> */}
              {/* <IconButton
                href="https://twitter.com"
                target="_blank"
                aria-label="Twitter"
                sx={{
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Twitter sx={{ color: "#00acee", fontSize: 30 }} />
              </IconButton> */}
              <IconButton
                href="https://www.instagram.com/draxhallfamilydental/"
                aria-label="Instagram"
                sx={{
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Instagram sx={{ color: "#C13584", fontSize: 30 }} />
              </IconButton>
              {/* <IconButton
                href="https://linkedin.com"
                target="_blank"
                aria-label="LinkedIn"
                sx={{
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <LinkedIn sx={{ color: "#0077b5", fontSize: 30 }} />
              </IconButton> */}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
