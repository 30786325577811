
const physioTheme = {
  palette: {
    primary: {
      main: "#700084",  // Main primary color
    },
    secondary: {
      main: "#662D91",  // Secondary color
    },
    info: {
      main: "#262262",  // Info or additional color
    },
  },
  typography: {
    h1: {
      color: "#700084",  // Primary color for main headings
    },
    h2: {
      color: "#662D91",  // Secondary color for subheadings
    },
    body1: {
      color: "#262262",  // Body text color
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#700084",  // Button background
          color: "#FFFFFF",  // Button text color
          "&:hover": {
            backgroundColor: "#662D91",  // Hover color for buttons
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#262262",  // AppBar background color
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: "#700084",  // Override color for h1 typography
        },
        h2: {
          color: "#662D91",  // Override color for h2 typography
        },
      },
    },
  },
};

export default physioTheme;
