import React from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import { AccessTime, Medication, InsertChartOutlined, Healing } from "@mui/icons-material";

const ProcedureSection: React.FC = () => {
  return (
    <Grid container spacing={6} id="procedure" alignItems={"stretch"}>
      {/* Endoscopy Section */}
      <Grid item xs={12} md={6} display={"flex"}>
        <Paper elevation={3} sx={{ p: 4, boxShadow: 2, borderRadius: 2, backgroundColor: "#f9f9f9" }}>
          <Typography variant="h5" gutterBottom textAlign="center">
            Endoscopy Procedure
          </Typography>

          {/* Sedation */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Medication sx={{ color: "#1976d2", mr: 2 }} />
            <Typography variant="h6">Sedation</Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You will be given a sedative to help you relax and minimize discomfort during the procedure.
          </Typography>

          {/* Insertion */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <InsertChartOutlined sx={{ color: "#1976d2", mr: 2 }} />
            <Typography variant="h6">Insertion</Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            The endoscope is gently inserted through the mouth (for upper GI endoscopy) or the rectum (for colonoscopy), allowing the doctor to visualize the internal structures on a monitor.
          </Typography>

          {/* Assessment */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccessTime sx={{ color: "#1976d2", mr: 2 }} />
            <Typography variant="h6">Assessment</Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            The physician may take biopsies (tissue samples) or perform minor procedures, such as removing polyps or treating bleeding.
          </Typography>

          {/* Duration */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccessTime sx={{ color: "#1976d2", mr: 2 }} />
            <Typography variant="h6">Duration</Typography>
          </Box>
          <Typography variant="body1">
            The procedure typically takes about 15 to 30 minutes, but additional time may be needed for recovery from sedation.
          </Typography>
        </Paper>
      </Grid>

      {/* Colonoscopy Section */}
      <Grid item xs={12} md={6} display={"flex"}>
        <Paper elevation={3} sx={{ p: 4, boxShadow: 2, borderRadius: 2, backgroundColor: "#f9f9f9" }}>
          <Typography variant="h5" gutterBottom textAlign="center">
            Colonoscopy Procedure
          </Typography>

          {/* Preparation */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Medication sx={{ color: "#1976d2", mr: 2 }} />
            <Typography variant="h6">Preparation</Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Similar to the endoscopy, you will receive sedation to ensure comfort during the procedure.
          </Typography>

          {/* Insertion */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <InsertChartOutlined sx={{ color: "#1976d2", mr: 2 }} />
            <Typography variant="h6">Insertion</Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            The colonoscope is inserted into the rectum and advanced through the colon to assess the health of the colon lining.
          </Typography>

          {/* Assessment and Intervention */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccessTime sx={{ color: "#1976d2", mr: 2 }} />
            <Typography variant="h6">Assessment & Intervention</Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            The doctor will carefully examine the lining of the colon, and any abnormalities can be addressed on the spot, such as removing polyps or taking biopsies.
          </Typography>

          {/* Recovery */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Healing sx={{ color: "#1976d2", mr: 2 }} />
            <Typography variant="h6">Recovery</Typography>
          </Box>
          <Typography variant="body1">
            After the procedure, you will spend some time in recovery until the sedation wears off. You may experience mild cramping or bloating, which typically resolves quickly.
          </Typography>
        </Paper>
      </Grid>

      {/* Conclusion Section */}
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 4, boxShadow: 2, borderRadius: 2, backgroundColor: "#f9f9f9" }}>
          <Typography variant="h5" gutterBottom textAlign="center">
            Conclusion
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Endoscopy and colonoscopy are vital procedures for diagnosing and managing gastrointestinal issues. With proper preparation and the expertise of our medical team, you can ensure a safe and effective experience.
          </Typography>
          <Typography variant="body1">
            If you have questions or concerns about whether these procedures are right for you, please don’t hesitate to contact us. Your health and well-being are our top priorities!
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProcedureSection;
