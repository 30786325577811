import React from "react";
import { Box, Typography, Button, IconButton, useTheme } from "@mui/material";
import { Email, Phone } from "@mui/icons-material";

const CallToActionSection: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      id="call-to-action"
      sx={{
        backgroundColor: "teal",
        color: "#fff",
        paddingY: 10,
        px: { xs: 5, md: 12 },
        textAlign: "center",
        position: "relative",
        overflow: "hidden",
        boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          maxWidth: "lg",
          margin: "0 auto",
          padding: "0 20px",
        }}
      >
        {/* Main heading */}
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            mb: 3,
            fontSize: "2.5rem",
            lineHeight: 1.4,
            letterSpacing: "0.5px",
            color: "#fff",
          }}
        >
          Get Evidence-Based Treatment for Your Orthopedic Pain or Injury
        </Typography>

        {/* Subheading */}
        <Typography
          variant="body1"
          sx={{
            mb: 8,
            fontWeight: 300,
            fontSize: "1.125rem",
            maxWidth: "600px",
            margin: "0 auto",
            color: "#e0e0e0",
            lineHeight: 1.7,
          }}
        >
          Take the first step toward recovery with personalized care. Whether
          you are dealing with a chronic condition or recovering from an injury,
          we're here to help.
        </Typography>

        {/* Call-to-Action Buttons */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, my: 4 }}>
          <Button
            variant="contained"
            color="secondary"
            href="mailto:draxhallmedical@gmail.com"
            sx={{
              padding: "8px",
              width: "fit-content",
              fontSize: "1.1rem",
              fontWeight: 400,
              backgroundColor: "#00a3ff",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#00a3ff",
                boxShadow: "none",
              },
            }}
          >
            Make an Appointment
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            href="href=tel:876-236-5215"
            sx={{
              padding: "8px",
              width: "fit-content",
              fontSize: "1.1rem",
              fontWeight: 400,
              borderColor: "#fff",
              backgroundColor: "transparent",
              color: "#fff",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            Request a Call
          </Button>
        </Box>

        {/* Contact Info with Icons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 3,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <IconButton
            href="mailto:draxhallmedical@gmail.com"
            sx={{ color: "#fff", fontSize: "2rem" }}
          >
            <Email fontSize="inherit" sx={{ mr: 1 }} />
            <Typography color={"white"}>draxhallmedical@gmail.com</Typography>
          </IconButton>
          <IconButton
            href="tel:876-236-5215"
            sx={{ color: "#fff", fontSize: "2rem" }}
          >
            <Phone fontSize="inherit" sx={{ mr: 1 }} />
            <Typography color={"white"}>876-236-5215</Typography>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CallToActionSection;
