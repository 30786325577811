import React from "react";
import { Box, Typography } from "@mui/material";
import InteractiveImage from "./InteractiveImage"; // Assuming the InteractiveImage component is in the same folder

const HeroSection: React.FC = () => {
  return (
    <Box
      id="hero-section"
      sx={{
        position: "relative",
        overflow: "hidden",
        paddingY: 0,
        px: {xs: 5, md: 12},
        backgroundColor: "#ffffff",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Stack on smaller screens
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          margin: "0 auto",
        }}
      >
        {/* Left Container: Text */}
        <Box
          sx={{
            flex: { xs: "0 0 100%", md: "1" }, // Full width on small screens, half on larger screens
            textAlign: { xs: "center", md: "left" }, // Center text on small screens
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{ fontWeight: 700 }}
          >
            Orthopedic Specialties
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "1.125rem", color: "#666", lineHeight: 1.6 }}
          >
            We are dedicated to delivering prompt, personalized care for every
            patient, ensuring a smooth and comfortable experience, no matter the
            musculoskeletal injury or condition.
          </Typography>
        </Box>

        {/* Right Container: Interactive Image */}
        <Box
          sx={{
            flex: { xs: "0 0 100%", md: "2" }, // Full width on small screens, larger proportion on larger screens
            maxWidth: { xs: "100%", md: "60%" }, // Restrict the max width of the image
            marginTop: { xs: "32px", md: "0" }, // Add spacing on small screens
          }}
        >
          <InteractiveImage />
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
