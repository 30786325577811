import {
  Box,
  Typography,
  Button,
  useTheme,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) => ({
  heroContainer: {
    position: "relative", // Ensure the container has relative positioning for layering
    backgroundImage: `url(/wallpaper.jpg)`, // Main background image (wallpaper.jpg)
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "auto", // Full screen height
  },
  overlayImage: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: "100%",
    zIndex: 2, // Ensure the image is above the wallpaper.jpg
  },
  textContent: {
    zIndex: 3, // Ensure text content stays above all images
    color: "white",
  },
}));

const Hero: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detect if screen size is small

  return (
    <Box
      id="hero"
      className={classes.heroContainer}
      sx={{
        display: "flex",
        justifyContent: isMobile ? "center" : "flex-start", // Center content on mobile
        alignItems: "center", // Vertically center content
        textAlign: isMobile ? "center" : "left", // Center text on mobile
        py: { xs: 2, md: 8 },
      }}
    >
      {/* Left Text Content */}
      <Box
        sx={{
          width: { xs: "100%", md: "70%", lg: "50%" }, // Full width on mobile
          pl: isMobile ? theme.spacing(5) : theme.spacing(15), // No padding on mobile
          pr: isMobile ? theme.spacing(5) : 0,
          pt: isMobile ? theme.spacing(8) : theme.spacing(10), // No padding on mobile
          position: "relative",
          zIndex: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          className={classes.textContent}
          sx={{
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            fontFamily: "Avenir Next LT Pro",
            fontWeight: "bold",
            color: theme.typography.h1.color,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
              textAlign: { xs: "center", md: "left" },
              fontFamily: "Avenir Next LT Pro",
              fontWeight: "bold",
              color: theme.palette.accent.main,
              // color: theme.palette.accent.main,
              display: "inline-block",
            }}
          >
            {" "}
            Welcome to
          </Typography>{" "}
          Drax Hall Medical and Surgical
        </Typography>
        <Typography
          paragraph
          className={classes.textContent}
          sx={{ fontWeight: 500, color: theme.typography.h1.color }}
          
        >
          At our practice, we blend expert care with genuine compassion,
          ensuring that your health journey is both effective and enjoyable. Our
          dedicated team offers a comprehensive range of services, from general
          doctor visits to specialized treatments such as orthopedic care,
          colonoscopies, endoscopies, and cardiac investigations. We also have a
          diverse group of medical specialists, including orthopedic surgeons,
          cardiologists, gastroenterologists, neurologists, rheumatologists,
          endocrinologists, hematologist, plastic surgeons, ENT specialists,
          general surgeons, pediatricians, obstetricians and gynecologists,
          thoracic surgeons, and more. Experience a warm, welcoming environment
          where everyone feels right at home.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-start", // Center buttons on mobile
            gap: 2,
            flexWrap: "nowrap",
          }}
        >
          <Button
            variant="contained"
            href="#contact-us"
            sx={{
              textTransform: "none",
              backgroundColor: theme.palette.accent.main,
              py: 1,
              px: 2,
              color: "white",
              borderRadius: 8,
              width: "fit-content",
              boxShadow: "none",
              "&:hover": {
                background: theme.palette.accent.main,
                boxShadow: "none",
              },
            }}
          >
            Make Appointment
          </Button>
          <Button
            variant="contained"
            href="#services"
            sx={{
              textTransform: "none",
              backgroundColor: "white",
              py: 1,
              px: 2,
              color: theme.typography.h1.color,
              borderRadius: 8,
              width: "fit-content",
              boxShadow: "none",
              "&:hover": {
                background: "white",
                boxShadow: "none",
              },
            }}
          >
            Our Services
          </Button>
        </Box>
      </Box>

      {/* Right Box (Image with opacity gradient) */}
      {!isMobile && (
        <Box
          className={classes.overlayImage}
          sx={{
            width: "100%",
            height: "100%", // Makes it fill the right side properly
            position: "absolute",
            bottom: 0,
            right: 0,
            overflow: "hidden",
          }}
        >
          <Box
            component={"img"}
            src="/medical-hero-removebg.png"
            alt="Medical Hero"
            width={"100%"}
            sx={{
              position: "absolute",
              bottom: {xs: "auto", md: -150, lg: -250},
              right: {xs: "auto", md: -150, lg: -200},
              objectFit: "contain",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Hero;
