import {
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Keyboard, Autoplay } from "swiper/modules";

// Importing Swiper styles for navigation, pagination, and scrollbar
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import dentalTheme from "../../../theme1";

const SmileGallery: React.FC = () => {
  const theme = useTheme();

  // Generate the image array dynamically
  const galleryImages = Array.from({ length: 17 }, (_, index) => {
    const imageNumber = index + 1; // To get image numbers 1 to 17
    const imageExtension = imageNumber >= 12 ? "jpg" : "png"; // Images 12-17 are JPG, others are PNG
    return `/images/dental/smile/${imageNumber}.${imageExtension}`;
  });

  return (
    <Box
      id="smile-gallery"
      sx={{
        my: 12,
        width: "100%",
        py: 8,
        px: { xs: 4, md: 12 },
      }}
    >
      {/* Main Heading */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Avenir Next LT Pro",
          fontWeight: "bold",
          color: dentalTheme.palette.primary.main,
        }}
      >
        Smile Gallery
      </Typography>

      {/* Attention-Grabbing Subheading */}
      <Typography
        variant="body2"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Roboto",
          color: "text.secondary",
          maxWidth: 800,
          mx: "auto",
        }}
      >
        Witness the Transformative Power of Our Dental Treatments
      </Typography>

      {/* Swiper Slider for Gallery */}
      <Swiper
        direction="horizontal"
        modules={[Navigation, A11y, Keyboard, Autoplay]} // Including necessary modules
        spaceBetween={16}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          600: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 3,
          },
        }}
        navigation
        keyboard={{ enabled: true }}
        style={{ width: "100%" }}
      >
        {galleryImages.map((image, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                borderRadius: 2,
                boxShadow: "none",
                overflow: "hidden", // Ensure images don't overflow the card
                position: "relative",
              }}
            >
              {/* Image Section */}
              <Box
                sx={{
                  position: "relative",
                  borderRadius: 1,
                  overflow: "hidden",
                  width: "100%",
                  height: "300px", // Fixed height for uniformity
                }}
              >
                {/* Image with gradient overlay on hover */}
                <img
                  src={image}
                  alt={`Dental Treatment ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "100%", // Ensure the image fills the container
                    objectFit: "cover", // Ensures the image covers the area without distortion
                    transition: "transform 0.3s ease-in-out",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background:
                      "linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1))",
                    opacity: 0,
                    transition: "opacity 0.3s ease-in-out",
                  }}
                />
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default SmileGallery;
