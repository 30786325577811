import { Box, Typography, Grid, Paper, useTheme } from "@mui/material";
import { LocalHospital, Accessibility, Assessment, Healing } from "@mui/icons-material"; // Importing relevant icons
import dentalTheme from "../../../theme1";

const Services: React.FC = () => {
  const theme = useTheme();
  
  const services = [
    {
      name: "Dental Cleaning",
      icon: <LocalHospital fontSize="large" sx={{ color: "#DE0C78" }} />,
      description:
        "Regular dental cleanings are essential for maintaining optimal oral health. We provide both routine cleanings for ongoing maintenance and deep cleanings for patients who require more intensive care.",
      treatments: [
        "Routine Cleanings",
        "Deep Cleanings",
      ],
      image:
        "https://markham7dental.com/wp-content/uploads/2022/06/Dental-Clinic-scaled.jpg", // Example image path, replace with real path
    },
    {
      name: "Extractions",
      icon: <Accessibility fontSize="large" sx={{ color: "#DE0C78" }} />,
      description:
        "Our extraction services include both standard and surgical extractions, ensuring that we can address even the most complex cases with care and precision.",
      treatments: [
        "Standard Extractions",
        "Surgical Extractions",
      ],
      image:
        "https://images.squarespace-cdn.com/content/v1/644fec3f070f47755b57773e/234ddb10-c597-4add-8b5a-3c7f3b753ba8/shutterstock_1714405492-4.jpg", // Example image path, replace with real path
    },
    {
      name: "Restorations",
      icon: <Assessment fontSize="large" sx={{ color: "#DE0C78" }} />,
      description:
        "We offer a variety of restorative treatments to repair and strengthen your teeth, ensuring a long-lasting and functional smile.",
      treatments: [
        "Dental Fillings",
        "Endodontic Treatment (Root Canals)",
        "Crowns and Crown Lengthening",
      ],
      image:
        "https://www.topdoctors.co.uk/files/Image/large/5be2ac88-930c-4b58-aa8d-357625bbab96.jpg", // Example image path, replace with real path
    },
    {
      name: "Cosmetic Treatment",
      icon: <Healing fontSize="large" sx={{ color: "#DE0C78" }} />,
      description:
        "Enhance the appearance of your smile with our cosmetic dentistry options, including teeth whitening and invisible aligners for a more confident you.",
      treatments: [
        "Teeth Whitening",
        "Composite Bonding",
        "Invisalign (Aligners)",
      ],
      image:
        "https://lastingsmilesofstratford.com/wp-content/uploads/2022/03/Barnum_Dental_Stratford_Stratford_Family_Dental-1-uai-1086x611.jpg", // Replace with actual image path
    },
    {
      name: "Rehabilitative Treatment",
      icon: <Healing fontSize="large" sx={{ color: "#DE0C78" }} />,
      description:
        "For those needing more extensive restoration, we provide dentures and other rehabilitative treatments to restore your smile's function and appearance.",
      treatments: [
        "Dentures",
      ],
      image:
        "https://www.rajkumarsdentistry.in/assets/image-new/services-detail/dental-rehabilitation.webp", // Replace with actual image path
    },
    {
      name: "Surgical Services",
      icon: <Healing fontSize="large" sx={{ color: "#DE0C78" }} />,
      description:
        "Our surgical services are designed to address various gum and bone issues, ensuring the health and aesthetics of your smile.",
      treatments: [
        "Osseous Surgery",
        "Flap Surgery",
        "Gingivectomy",
        "Gingival Graft",
        "Tissue Graft",
        "Bone Graft",
        "Tissue Regeneration",
        "Labial Frenectomy",
        "Lingual Frenectomy",
      ],
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5jesSdYjxQY21ZQ2fiJzkgkFWOO_7evg_aA&s", // Replace with actual image path
    },
  ];

  return (
    <Box
      id="services"
      sx={{
        py: 8,
        px: {xs: theme.spacing(5), md: theme.spacing(12)},
        backgroundColor: "rgba(2,73,117, 0.1)",
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Avenir Next LT Pro",
          fontWeight: "bold",
          color: dentalTheme.palette.primary.main,
        }}
      >
        Our Dental Services
      </Typography>
      <Grid container spacing={4} justifyContent="center" py={8}>
        {services.map((service) => (
          <Grid item xs={12} md={4} key={service.name}>
            <Paper
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                height: "100%",
                boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                borderRadius: 16,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    ml: 2,
                    color: dentalTheme.palette.secondary.main,
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  {service.name}
                </Typography>
              </Box>
              <img
                src={service.image}
                alt={service.name}
                style={{
                  width: "100%",
                  borderRadius: 8,
                  marginBottom: theme.spacing(2),
                }}
              />
              <Typography gutterBottom>{service.description}</Typography>

              {/* Treatments */}
              <Typography
                variant="h6"
                sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
              >
                Treatments:
              </Typography>
              <ul>
                {service.treatments.map((treatment, index) => (
                  <li key={index}>
                    <Typography>{treatment}</Typography>
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
