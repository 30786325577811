import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Link,
  useMediaQuery,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";

const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const links = [
    { label: "Home", href: "#hero-section" },
    { label: "Specialties", href: "#specialty-list" },
    { label: "Care Options", href: "#care-options" },
    // { label: 'Urgent Care', href: '#urgent-care' },
    { label: "Contact", href: "#call-to-action" },
  ];

  const renderNavLinks = (isDrawer: boolean = false) => (
    <List
      sx={{ display: isDrawer ? "block" : "flex", padding: 0, width: "auto" }}
    >
      {links.map((link) => (
        <ListItem
          key={link.href}
          sx={{
            padding: isDrawer ? "10px 20px" : "0",
            display: isDrawer ? "block" : "inline",
            width: "100%",
          }}
        >
          <Link
            href={link.href}
            underline="none"
            color="inherit"
            sx={{
              padding: isDrawer ? "0" : "0 16px",
              fontWeight: 500,
              "&:hover": { color: theme.palette.primary.main },
              width: "100%",
            }}
          >
            <ListItemText
              primary={link.label}
              sx={{ textAlign: isDrawer ? "left" : "center", width: "100%" }}
            />
          </Link>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ backgroundColor: "white" }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            paddingX: { xs: 2, md: "64px" },
            backgroundColor: "transparent",
          }}
        >
          {/* Logo */}
          {/* Logo aligned to the left */}
          <img src="/logo.png" width={45} height={45} alt="Logo" />

          {/* Desktop Navigation */}
          {!isMobile && <Box sx={{ width: "50%" }}>{renderNavLinks()}</Box>}

          {/* Mobile Menu Icon */}
          {isMobile && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <Box
          sx={{
            width: 250,
            padding: 2,
          }}
          role="presentation"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          {renderNavLinks(true)}
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
