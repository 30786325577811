import React from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

const HeroSection: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      id="home"
      sx={{
        backgroundImage: 'url("/hero-image.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "60vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        color: theme.palette.common.white,
        py: 6,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: 700,
          mb: 2,
          color: "white",
          px: { xs: 0, sm: 5, md: 8, lg: 10 },
        }}
      >
        Comprehensive Guide to Endoscopy & Colonoscopy
      </Typography>
      <Typography variant="h6" sx={{ mb: 3, color: "white" }}>
        Learn about procedures that can improve your gastrointestinal health.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        endIcon={<ArrowForward />}
        href="#procedure"
        sx={{
          backgroundColor: "#922161",
        }}
      >
        Learn More
      </Button>
    </Box>
  );
};

export default HeroSection;
