import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

const NavigationBar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Navigation items in an array
  const navItems = [
    { name: "Home", href: "#home" },
    { name: "Endoscopy", href: "#endoscopy" },
    { name: "Colonoscopy", href: "#colonoscopy" },
    { name: "Need for Procedures", href: "#need-for-procedures" },
    { name: "Preparation", href: "#preparation" },
    { name: "Procedure", href: "#procedure" },
  ];

  // Drawer handling for mobile
  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar
        position="sticky"
        color="primary"
        sx={{
          backgroundColor: "white",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          px: 1
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: "transparent",
            justifyContent: "space-between",
          }}
        >
          <img src="/logo.png" width={45} height={45} alt="Logo" />

          {isMobile ? (
            <>
              <IconButton
                edge="start"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ color: theme.palette.primary.main }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                <Box
                  sx={{
                    width: 250,
                    backgroundColor: "white", // Ensure the drawer has a white background
                    padding: "16px",
                  }}
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <List>
                    {navItems.map((item) => (
                      <ListItem button key={item.href}>
                        <Button
                          href={item.href}
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            color: "#922161",
                            backgroundColor: "transparent", // No background color
                            textTransform: "none", // To remove the capitalized text
                            "&:hover": {
                              backgroundColor: "transparent", // No hover effect
                            },
                          }}
                        >
                          <ListItemText
                            primary={item.name}
                            sx={{ color: "#922161", fontSize: "16px" }}
                          />
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: "flex", gap: 4 }}>
              {navItems.map((item) => (
                <Button
                  key={item.href}
                  sx={{
                    color: "#922161",
                    backgroundColor: "transparent", // No background color
                    textTransform: "none", // To remove the capitalized text
                    "&:hover": {
                      backgroundColor: "transparent", // No hover effect
                    },
                  }}
                  href={item.href}
                >
                  {item.name}
                </Button>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavigationBar;
