import React, { useState } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled Box for the Container
const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 'auto',
  margin: '0 auto',
}));

// Styled Dot for Labels
const LabelDot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  backgroundColor: '#00a3ff',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#ff007f',
    boxShadow: '0 0 10px 3px rgba(255, 0, 127, 0.8)',
  },
}));

// Label Data
const labels = [
  { id: 1, name: 'Back & Neck', x: '45%', y: '40%', description: 'Back & Neck pain relief...' },
  { id: 2, name: 'Shoulder', x: '52%', y: '27%', description: 'Shoulder pain treatments...' },
  { id: 3, name: 'Elbow', x: '35%', y: '20%', description: 'Elbow injury care...' },
  { id: 4, name: 'Hand & Wrist', x: '25%', y: '38%', description: 'Hand and wrist treatments...' },
  { id: 5, name: 'Hip & Thigh', x: '50%', y: '57%', description: 'Hip & Thigh solutions...' },
  { id: 6, name: 'Knee & Leg', x: '70%', y: '50%', description: 'Knee & Leg treatments...' },
  { id: 7, name: 'Foot & Ankle', x: '72%', y: '80%', description: 'Foot & Ankle care...' },
];

const InteractiveImage: React.FC = () => {
  const [hoveredLabel, setHoveredLabel] = useState<string | null>(null);

  const handleHover = (label: string | null) => {
    setHoveredLabel(label);
  };

  const scrollToSection = () => {
    const section = document.getElementById('care-options-tabs');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <ImageContainer>
      <img
        src="/ortho-image.png" // Use your actual image path here
        alt="Labeled body parts"
        style={{ width: '100%', display: 'block' }}
      />
      {labels.map((label) => (
        <Tooltip key={label.id} title={label.name} arrow>
          <LabelDot
            style={{ top: label.y, left: label.x }}
            onMouseEnter={() => handleHover(label.name)}
            onMouseLeave={() => handleHover(null)}
            onClick={scrollToSection}
          />
        </Tooltip>
      ))}
      {/* {hoveredLabel && (
        <Typography
          variant="h6"
          sx={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: '#fff',
            padding: '8px 16px',
            borderRadius: '8px',
          }}
        >
          {hoveredLabel}
        </Typography>
      )} */}
    </ImageContainer>
  );
};

export default InteractiveImage;
