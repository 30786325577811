import { createTheme } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';

// Extend the palette to add custom colors
declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
  }
  interface PaletteOptions {
    accent?: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#352D76',  // Main color for headings and important text
    },
    secondary: {
      main: '#057291',  // Button and link color
    },
    info: {
      main: '#34BEDA',  // For informational elements
    },
    accent: {
      main: '#922161',  // Accent color used for call-to-actions
    },
    background: {
      default: '#F4F4F4',  // Default background color for sections
      paper: '#FFFFFF',  // Background for cards or paper-like elements (e.g., testimonials)
    },
  },
  typography: {
    h1: {
      color: '#352D76',  // Primary heading
    },
    h2: {
      color: '#057291',  // Subheading color
    },
    body1: {
      color: '#352D76',  // Main text color
    },
    button: {
      textTransform: 'none',
      color: '#FFFFFF',  // Button text color
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#057291',  // Primary button background
          color: '#FFFFFF',  // Button text
          '&:hover': {
            backgroundColor: '#922161',  // Hover state using the accent color
          },
        },
      },
    },
  },
});

export default theme;
