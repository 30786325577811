import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  ListItemIcon,
  useTheme,
  Button,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ScheduleIcon from "@mui/icons-material/Schedule";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const faqs = [
  {
    question: "Do I need a referral to see a physiotherapist?",
    answer:
      "Yes, a referral is required for all new patients. Each referral is valid for up to six (6) months.",
  },
  {
    question: "How long is a typical session?",
    answer:
      "Sessions typically last between 60 to 90 minutes, depending on the treatment plan.",
  },
  {
    question: "What should I wear to my appointment?",
    answer:
      "Please wear loose, comfortable clothing that allows easy access to the area being treated.",
  },
];

const PatientInfo: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      id="patient-info"
      sx={{ py: 8, px: { xs: 2, sm: 4, md: 12 }, backgroundColor: "#f9f9f9" }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: theme.typography.h1.color,
          mb: 10,
          fontFamily: "Roboto",
        }}
      >
        Patient Information
      </Typography>

      <Box sx={{ py: 8, px: 4, backgroundColor: "#f9f9f9" }}>
        <Grid container spacing={4}>
          {/* New Patient Instructions */}
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{ p: 4, borderRadius: 3, backgroundColor: "lavender" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                }}
              >
                New Patient Instructions
              </Typography>
              <Box>
                <Accordion
                  sx={{
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    boxShadow: "none",
                    borderRadius: "8px",
                    "&:before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#1C3052" }} />}
                    sx={{ padding: "0 16px" }}
                  >
                    <ListItemIcon>
                      <CreditCardIcon sx={{ color: "#1C3052" }} />
                    </ListItemIcon>
                    <Typography sx={{ fontWeight: 500, color: "#1C3052" }}>
                      Bring insurance card for verification
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingLeft: "56px" }}>
                    <Typography sx={{ color: "#555" }}>
                      Please bring your insurance card for verification and
                      processing.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    boxShadow: "none",
                    borderRadius: "8px",
                    "&:before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#1C3052" }} />}
                    sx={{ padding: "0 16px" }}
                  >
                    <ListItemIcon>
                      <ScheduleIcon sx={{ color: "#1C3052" }} />
                    </ListItemIcon>
                    <Typography sx={{ fontWeight: 500, color: "#1C3052" }}>
                      Arrive 15 minutes early for paperwork
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingLeft: "56px" }}>
                    <Typography sx={{ color: "#555" }}>
                      Arriving early helps complete all necessary paperwork
                      before your scheduled appointment.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    boxShadow: "none",
                    borderRadius: "8px",
                    "&:before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#1C3052" }} />}
                    sx={{ padding: "0 16px" }}
                  >
                    <ListItemIcon>
                      <HelpOutlineIcon sx={{ color: "#1C3052" }} />
                    </ListItemIcon>
                    <Typography sx={{ fontWeight: 500, color: "#1C3052" }}>
                      Bring medical records and imaging reports
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingLeft: "56px" }}>
                    <Typography sx={{ color: "#555" }}>
                      Be sure to bring any relevant medical records, imaging
                      reports, and a list of medications.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Paper>
          </Grid>

          {/* Insurance Policies */}
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{ p: 4, borderRadius: 3, backgroundColor: "lavender" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                }}
              >
                Insurance Policies & Payment Options
              </Typography>
              <Box>
                <Accordion
                  sx={{
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    boxShadow: "none",
                    borderRadius: "8px",
                    "&:before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#1C3052" }} />}
                    sx={{ padding: "0 16px" }}
                  >
                    <ListItemIcon>
                      <LocalHospitalIcon sx={{ color: "#1C3052" }} />
                    </ListItemIcon>
                    <Typography sx={{ fontWeight: 500, color: "#1C3052" }}>
                      We accept most major insurance plans
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingLeft: "56px" }}>
                    <Typography sx={{ color: "#555" }}>
                      We accept most major insurance plans, including Sagicor,
                      Canopy, and Medecus (Guardian Life). Please confirm with
                      your insurance provider regarding coverage for physiotherapy
                      services.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    boxShadow: "none",
                    borderRadius: "8px",
                    "&:before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#1C3052" }} />}
                    sx={{ padding: "0 16px" }}
                  >
                    <ListItemIcon>
                      <CreditCardIcon sx={{ color: "#1C3052" }} />
                    </ListItemIcon>
                    <Typography sx={{ fontWeight: 500, color: "#1C3052" }}>
                      Flexible payment options
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingLeft: "56px" }}>
                    <Typography sx={{ color: "#555" }}>
                      We offer flexible payment options, including cash, credit
                      card, and direct billing for certain insurers.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          textAlign: "center",
          padding: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{ fontWeight: "bold", marginBottom: "16px" }}
        >
          We're here to answer all your questions.
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginBottom: "32px", color: "#888", width: "60%" }}
        >
          If you have any more questions, feel free to get in touch with us.
        </Typography>

        <Box sx={{ maxWidth: "md", margin: "auto" }}>
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                marginBottom: "16px",
                borderRadius: "8px",
                boxShadow: "none",
                border: "1px solid #e0e0e0",
                "&:before": { display: "none" },
                "&.Mui-expanded": {
                  margin: "auto",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  padding: "16px",
                }}
              >
                <Typography sx={{ fontWeight: 500 }}>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "16px",
                  backgroundColor: "#f9f9f9",
                  textAlign: "left",
                }}
              >
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Typography
          variant="body2"
          sx={{ marginTop: "32px", marginBottom: "16px", color: "#888" }}
        >
          Got any more questions?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="mailto:draxhallphysio@gmail.com"
          sx={{
            textTransform: "none",
            backgroundColor: "#6C63FF",
            padding: "10px 24px",
            borderRadius: "24px",
            boxShadow: "none",
          }}
        >
          Get in touch
        </Button>
      </Box>
    </Box>
  );
};

export default PatientInfo;
