import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import dentalTheme from "../../../theme1";

// Data for the team members
const teamMembers = [
  {
    name: "Dr. Andre Ranger",
    position: "Cosmetic Dentist",
    image: "/images/dental/ranger.png",
    credentials: "Doctor of Dental Surgery",
    bio: "Dr. Ranger received his dental education in Moscow, Russia. After graduating in 2015, he has never dropped a tool. Dr. Ranger is a highly skilled dentist with a deep passion for cosmetic dentistry. He believes that a confident smile can transform lives and is committed to helping patients achieve their ideal appearance through advanced cosmetic treatments. With a meticulous eye for detail and a dedication to staying current with the latest dental innovations, Dr. Ranger ensures that every patient leaves with a smile they can be proud of. For Dr. Ranger, NO question is a stupid question.",
  },
  {
    name: "Egon Taylor",
    position: "Dental Hygienist",
    image: "/images/dental/taylor.jpg",
    credentials: "Bachelor of Science in Dental Hygiene",
    bio: "Egon Taylor is our Registered Dental Hygienist (RDH) who holds a Bachelor of Science in Dental Hygiene and currently a Graduate Student in the Master of Science in Dental Therapy programme at University of Technology, Jamaica (UTECH, Ja). He is a dedicated dental hygienist with six (6) years of experience who is committed to educating patients about the importance of their overall dental health. He believes that understanding how oral care impacts general well-being is essential for long-term dental wellness. Mr Taylor works closely with patients to provide personalized care and guidance, empowering them to take control of their dental health and maintain their beautiful smiles.",
  },
  {
    name: "Dr. Parkin-Edwin",
    position: "Periodontist",
    image: "/images/dental/edwin.png",
    credentials: "Certificate in Periodontics",
    bio: "Dr. Ruth Parkin-Edwin grew up in Jamaica. She migrated to the USA in 1991. She received her BS (Summa Cum Laude) in Biology from Upsala College, 1994. After college she immediately was accepted to Dental school in 1994. She graduated from the University of Medicine and Dentistry New Jersey in 1998 with honors. She completed her three years specialty and received her Certificate in Periodontics. Dr. Parkin-Edwin is an active member of various dental associations and has been involved in multiple research publications.",
  },
  {
    name: "Dr. Garel",
    position: "Doctor of Medical Dentistry",
    image: "/images/dental/garel.jpg",  // Placeholder for the image
    credentials: "Doctor of Medical Dentistry, University of Technology, Jamaica",
    bio: "Dr. Garel received a Doctor of Medical Dentistry at the University of Technology, Jamaica. Throughout her years of practice, she has gained experience in both clinic and hospital-level dentistry. She is passionate about the prevention of oral diseases and helping patients meet their oral health goals. Dr. Garel is particularly passionate about ensuring pediatric patients have a great experience and a solid oral hygiene foundation. Additionally, she has an interest in dental sleep medicine for the treatment of sleep-related breathing issues. Dr. Garel truly believes that good oral health is key to overall health."
  }
  
];

function AboutUs() {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState<any>(null);

  const handleOpenModal = (member: any) => {
    setSelectedMember(member);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box
      id="about-us"
      sx={{
        py: 8,
        backgroundColor: "lightgray",
        px: { xs: 5, md: 10, lg: 20 },
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Heading Section */}
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "Avenir Next LT Pro",
            fontWeight: "bold",
            color: dentalTheme.palette.primary.main,
            mb: theme.spacing(4),
          }}
        >
          Meet Our Dedicated Dental Team
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: theme.spacing(8),
            textAlign: "center",
            color: "text.secondary",
            width: { xs: "80%", md: "70%", lg: "50%" },
          }}
        >
          Our experienced team of dentists and support staff is here to provide
          exceptional care and expertise for all your dental needs.
        </Typography>

        {/* Team Members Grid */}
        <Grid container spacing={2} justifyContent={"center"}>
          {teamMembers.map((member) => (
            <Grid item xs={12} sm={6} md={4} lg={2.6} key={member.name}>
              <Card
                sx={{
                  textAlign: "center",
                  boxShadow: "none",
                  backgroundImage: `url(${member.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: {xs: "500px", md: "300px"},
                  position: "relative",
                }}
              >
                <CardContent
                  sx={{
                    textAlign: "left",
                    p: 1,
                    backgroundColor: "white",
                    position: "absolute",
                    bottom: 12,
                    left: 12,
                    right: 12,
                    height: "auto",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "black", fontWeight: "bold" }}
                  >
                    {member.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 1 }}
                  >
                    {member.position}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => handleOpenModal(member)}
                    sx={{
                      color: "black",
                      backgroundColor: "transparent",
                      p: 0,
                      border: "none",
                      "&:hover": {
                        backgroundColor: "transparent",
                        border: "none",
                      }
                    }}
                  >
                    Read More...
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Modal for Full Team Member Details */}
        {selectedMember && (
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>{selectedMember.name}</DialogTitle>
            <DialogContent>
              <Typography variant="h6">{selectedMember.position}</Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                {selectedMember.bio}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    </Box>
  );
}

export default AboutUs;
