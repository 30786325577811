import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  useTheme,
  // IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
import physioTheme from "../../../theme2";

// Team member details
const teamMembers = [
  {
    name: "Daina Smith",
    role: "Physiotherapist",
    bio: "Daina Smith is a registered Physiotherapist. After completing internships at the Sir John Golding Rehabilitation Centre, Savanna la Mar Public General Hospital, and Cornwall Regional Hospital, she specialized in orthopedic and sports rehabilitation at Fast Recovery Human Tecar Treatment Centre. Daina is passionate about creating personalized treatment plans to help patients achieve optimal recovery and continues to stay updated on the latest physiotherapy techniques.",
    profileImage: "/images/physio/daina.jpg", // Replace with actual image path
  },
  {
    name: "Sanatta McLeggon",
    role: "Clinical Massage Therapist",
    bio: "Sanatta McLeggon is an ITEC-certified Muscle Fiber Methodology Clinical Massage Therapist. She specializes in rehabilitating and preventing muscle imbalances, such as Frozen Shoulder, Piriformis Syndrome, and Carpal Tunnel Syndrome, using a combination of passive stretching and muscle fiber manipulation. Sanatta’s approach helps clients improve performance and relieve stress-related muscular issues.",
    profileImage: "", // Replace with actual image path
  },
];

const mainColor = physioTheme.palette.primary.main;
// const secondaryColor = physioTheme.palette.secondary.main;
const tertiaryColor = physioTheme.palette.info.main;

// Styling for the overall section
const TeamSectionWrapper = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to bottom, ${mainColor} 50%, white 70%)`,
  padding: `${theme.spacing(2)} ${theme.spacing(8)}`, // Correct padding logic
  textAlign: "center",
  borderRadius: 20,
  [theme.breakpoints.up("xs")]: {
    padding: theme.spacing(2), // padding for xs screen size
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(8), // padding for md screen size
  },
}));

// Styling for the individual team member card
const TeamCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "16px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  padding: theme.spacing(2),
  textAlign: "center",
  backgroundColor: "white",
}));

const AboutUs = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderTopLeftRadius: { xs: 20, md: 50, lg: 80 },
        borderTopRightRadius: { xs: 20, md: 50, lg: 80 },
        mt: theme.spacing(12),
        py: 8,
        px: theme.spacing(8),
        backgroundColor: "white",
      }}
      id="about-us"
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Avenir Next LT Pro",
          fontWeight: "bold",
          color: tertiaryColor,
          marginY: theme.spacing(6),
        }}
      >
        Get To Know Us
      </Typography>
      <Box sx={{ mb: theme.spacing(8) }}>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="stretch"
        >
          {/* Vision */}
          <Grid item xs={12} md={4} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: theme.spacing(4),
                backgroundColor: "lavender",
                borderRadius: 8,
                flex: 1,
              }}
            >
              <img src="/vision.png" width={70} height={70} alt="Vision icon" />
              <Typography
                variant="h4"
                sx={{
                  marginTop: theme.spacing(2),
                  fontWeight: "bold",
                  color: "#2C3E50",
                }}
              >
                Vision
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: theme.spacing(2), color: "#5D6D7E" }}
              >
                To be the leading center for rehabilitation and wellness,
                inspiring a healthier and more active community by providing
                innovative and compassionate care.
              </Typography>
            </Box>
          </Grid>

          {/* Mission */}
          <Grid item xs={12} md={4} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: theme.spacing(4),
                backgroundColor: "lavender",
                borderRadius: 8,
                flex: 1,
              }}
            >
              <img
                src="mission.png"
                width={70}
                height={70}
                alt="Mission icon"
              />
              <Typography
                variant="h4"
                sx={{
                  marginTop: theme.spacing(2),
                  fontWeight: "bold",
                  color: "#2C3E50",
                }}
              >
                Mission
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: theme.spacing(2), color: "#5D6D7E" }}
              >
                To enhance the quality of life for every patient through
                personalized, high-quality care. We are dedicated to improving
                mobility, health, and wellness by offering exceptional
                physiotherapy, massage therapy, and wellness programs in a
                supportive and welcoming environment.
              </Typography>
            </Box>
          </Grid>

          {/* History */}
          <Grid item xs={12} md={4} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: theme.spacing(4),
                backgroundColor: "lavender",
                borderRadius: 8,
                flex: 1,
              }}
            >
              <img
                src="/history.png"
                width={70}
                height={70}
                alt="History icon"
              />
              <Typography
                variant="h4"
                sx={{
                  marginTop: theme.spacing(2),
                  fontWeight: "bold",
                  color: "#2C3E50",
                }}
              >
                History
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: theme.spacing(2), color: "#5D6D7E" }}
              >
                Established in 2023, Drax Hall Physiotherapy & Rehab has quickly
                become a trusted name in rehabilitation and wellness. Rooted in
                the belief that everyone deserves access to high-quality,
                personalized care, we have built a space where patients feel
                empowered to heal and thrive. Our journey continues as we expand
                our services to meet the growing needs of our community.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <TeamSectionWrapper>
        {/* Section Title */}
        <Typography
          variant="h3"
          sx={{
            color: "white",
            fontWeight: "bold",
            marginBottom: 8,
            textAlign: "center",
          }}
        >
          Meet The Team
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: "white",
            marginBottom: 8,
            textAlign: "center",
          }}
        >
          At Drax Hall Physiotherapy & Rehab, our team is dedicated to providing
          exceptional care tailored to your needs.
        </Typography>

        {/* Team Members Grid */}
        <Grid container spacing={4} justifyContent="center" alignItems={"stretch"}>
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} display={"flex"}>
              <TeamCard>
                {/* Rectangular Profile Image */}
                <Box
                  component="img"
                  src={member.profileImage.length ?  member.profileImage : "/placeholder-person.webp"}
                  alt={member.name}
                  sx={{
                    width: "100%",
                    height: "250px",
                    objectFit: "cover",
                    borderRadius: "8px 8px 0 0", // Rounded at top only
                  }}
                />

                <CardContent>
                  {/* Name and Role */}
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", marginTop: "16px" }}
                  >
                    {member.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{ marginBottom: "16px" }}
                  >
                    {member.role}
                  </Typography>

                  {/* Bio */}
                  <Typography
                    variant="body2"
                    sx={{ marginBottom: "24px", color: "text.secondary" }}
                  >
                    {member.bio}
                  </Typography>

                  {/* Social Media Icons */}
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <IconButton
                      aria-label="Facebook"
                      sx={{ color: tertiaryColor }}
                    >
                      <FacebookIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Twitter"
                      sx={{ color: tertiaryColor }}
                    >
                      <TwitterIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Instagram"
                      sx={{ color: tertiaryColor }}
                    >
                      <InstagramIcon />
                    </IconButton>
                  </Box> */}
                </CardContent>
              </TeamCard>
            </Grid>
          ))}
        </Grid>
      </TeamSectionWrapper>
    </Box>
  );
};

export default AboutUs;
