const dentalTheme = {
  palette: {
    primary: {
      main: "#662D91",  // Updated color code for primary
    },
    secondary: {
      main: "#00AEEF",  // Updated color code for secondary
    },
    info: {
      main: "#262262",  // Updated color code for info or additional highlights
    },
  },
  typography: {
    h1: {
      color: "#662D91",  // Updated color for primary headings
    },
    h2: {
      color: "#00AEEF",  // Updated color for subheadings
    },
    body1: {
      color: "#262262",  // Updated color for body text
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#662D91",  // Updated button background
          color: "#FFFFFF",  // Button text color
          "&:hover": {
            backgroundColor: "#00AEEF",  // Hover color for buttons
          },
        },
      },
    },
  },
};

export default dentalTheme;
