import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";
import { Phone, Email, AccessTime, LocationOn } from "@mui/icons-material";
import { Facebook, Instagram } from "@mui/icons-material";

const ContactUs: React.FC = () => {
  const theme = useTheme();

  // State to manage form input
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Handle the "Send Message" button click
  const handleSendMessage = () => {
    const subject = "Contact Us Message";
    const body = `Name: ${name}\nEmail: ${email}\n\nMessage: ${message}`;
    const mailtoLink = `mailto:draxhallmedical@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Box
      id="contact-us"
      sx={{
        py: 8,
        px: { xs: theme.spacing(5), md: theme.spacing(12) },
        backgroundColor: "rgba(2,73,117, 0.1)",
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: theme.typography.h1.color,
          mb: theme.spacing(4),
        }}
      >
        Contact Us
      </Typography>

      {/* Introduction */}
      <Typography variant="body1" sx={{ mb: 4 }}>
        If you’re seeking high-quality services and products, you’ve come to the
        right place. We are dedicated to delivering an exceptional service
        experience and a personalized approach for each patient. With years of
        experience under our belt, we ensure your satisfaction with the
        top-notch work we provide.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Feel free to reach out with any questions or inquiries. We look forward
        to hearing from you!
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {/* Contact Form */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Name"
            fullWidth
            sx={{ mb: 2 }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Email"
            fullWidth
            sx={{ mb: 2 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Message"
            multiline
            rows={4}
            fullWidth
            sx={{ mb: 2 }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            sx={{
              backgroundColor: "#DE0C78",
              "&:hover": {
                backgroundColor: "#a00d56",
              },
              mt: 2,
            }}
            onClick={handleSendMessage} // Trigger the mailto when clicked
          >
            Send Message
          </Button>
        </Grid>

        {/* Contact Details */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: "left" }}
        >
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Phone sx={{ mr: 1, color: "#DE0C78" }} />
              Landline: 876-972-0514
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Phone sx={{ mr: 1, color: "#DE0C78" }} />
              Cell: 876-552-3592
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Phone sx={{ mr: 1, color: "#DE0C78" }} />
              WhatsApp: 876-236-5215
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Email sx={{ mr: 1, color: "#DE0C78" }} />
              draxhallmedical@gmail.com
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ display: "flex", alignItems: "center" }}
            >
              <LocationOn sx={{ mr: 1, color: "#DE0C78" }} />
              Shop#: 14 Knutsford Express Business Centre, 12 Drax Hall
              Commercial Estate, Drax Hall St.Ann.
            </Typography>
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <AccessTime sx={{ mr: 1, color: "#DE0C78" }} />
              Office Hours:
              <br />
              Mondays to Fridays: 10am-6pm
              <br />
              Saturdays: 8am-4pm
            </Typography>
          </Box>

          {/* Social Media Icons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              gap: 2,
            }}
          >
            <IconButton
              href="https://facebook.com/draxhallmedicalandsurgical"
              target="_blank"
            >
              <Facebook sx={{ color: "#3b5998", fontSize: 30 }} />
            </IconButton>
            <IconButton
              href="https://instagram.com/draxhall_medsurge"
              target="_blank"
            >
              <Instagram sx={{ color: "#DE0C78", fontSize: 30 }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
