import React, { useState, useEffect } from 'react';
import { Fab, Zoom, useTheme } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Navbar from './components/Navbar';
import AboutUs from './components/AboutUs';
import Hero from './components/Hero';
import Services from './components/Services';
import PatientInfo from './components/PatientInfo';
import ContactUs from './components/ContactUs';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import MedicalGallery from './components/MedicalGallery';
import Orthopedic from './components/Orthopedic';
import EndoscopyColonoscopySection from './components/EndoscopyColonoscopy';

const MedicalPage: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const theme = useTheme();

  // Track scrolling to toggle visibility of the scroll to top button
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Hero />
      <AboutUs />
      <Services />
      <Orthopedic/>
      <EndoscopyColonoscopySection/>
      <MedicalGallery />
      <PatientInfo />
      <ContactUs />
      <Testimonials />
      <Footer />

      {/* Scroll to Top Button */}
      <Zoom in={isVisible}>
        <Fab
          color="secondary"
          size="small"
          onClick={scrollToTop}
          aria-label="scroll back to top"
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 100,
            backgroundColor: theme.palette.accent.main,
            '&:hover': {
              backgroundColor: '#a00d56',
            },
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Zoom>
    </>
  );
};

export default MedicalPage;
