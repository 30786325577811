import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const orthopedicServices = [
  {
    title: "Hand & Wrist",
    image:
      "https://www.keithraskinmd.com/wp-content/uploads/2020/10/Carpal-Tunnel-Syndrome.jpg",
    link: "https://www.floridaortho.com/specialties/hand-wrist/",
  },
  {
    title: "Back & Neck",
    image:
      "https://ssbhealthcare.com/wp-content/uploads/2023/02/Untitled-1.png",
    link: "https://www.floridaortho.com/specialties/spine/",
  },
  {
    title: "Foot & Ankle",
    image:
      "https://siortho.com/wp-content/uploads/2022/10/shutterstock_1016392276.jpg",
    link: "https://www.floridaortho.com/specialties/foot-ankle/",
  },
  {
    title: "Elbow",
    image:
      "https://media.post.rvohealth.io/wp-content/uploads/2022/02/man_in_physical_therapy_for_tennis_elbow-1200x628-facebook-1200x628.jpg",
    link: "https://www.floridaortho.com/specialties/elbow/",
  },
  {
    title: "Knee & Leg",
    image:
      "https://images.everydayhealth.com/images/pain-management/knee-pain/knee-pain-symptoms-and-possible-causes-1440x810.jpg",
    link: "https://www.floridaortho.com/specialties/knee-leg/",
  },
  {
    title: "Hip & Thigh",
    image:
      "https://pttimewithtim.com/wp-content/uploads/2021/04/Hip-Bursitis-Trochanteric-Bursitis-Pain.jpg",
    link: "https://www.floridaortho.com/specialties/hip-thigh/",
  },
  {
    title: "Shoulder",
    image:
      "https://www.bone-joint.com/wp-content/uploads/sites/393/2024/03/Rotator-Cuff-Tear.jpg.webp",
    link: "https://www.floridaortho.com/specialties/shoulder/",
  },
];

const Orthopedic: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        py: 8,
        px: { xs: 2, sm: 4, md: 12 },
        textAlign: "center",
        backgroundColor: "#f9f9f9",
      }}
    >
      {/* Heading */}
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
        Personalized solutions for every orthopedic need
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        FOI physicians provide a responsive, personalized care plan for any
        musculoskeletal injury or condition.
      </Typography>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems={"stretch"}
      >
        {orthopedicServices.map((service, index) => (
          <Grid item xs={6} sm={4} md={3} lg={1.7} key={index} display={"flex"}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: "none",
                backgroundColor: "transparent",
                position: "relative",
                overflow: "hidden", // Ensures the pseudo-element stays within the card boundary
                "&:hover": {
                  backgroundColor: "transparent", // Prevent background color on hover
                },
              }}
            >
              <CardActionArea
                href={"/orthopedic#specialty-list"}
                sx={{
                  position: "relative",
                  boxShadow: "none",
                  overflow: "hidden",
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent", // Prevent background color on hover
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "20%", // Ensure consistent border radius
                    overflow: "hidden",
                    height: 150, // Fixed height
                    width: 150, // Fixed width
                    "&:hover img": {
                      transform: "scale(1.1)", // Grow animation on hover
                      transition: "transform 0.3s ease-in-out",
                    },
                    "&:hover::before": {
                      content: '""',
                      position: "absolute",
                      zIndex: 2,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "20%", // Ensure border radius consistency
                      backgroundImage:
                        "linear-gradient(90deg, rgba(227,234,114,0.5) 0%, rgba(3,125,184,0.5) 90%)",
                      opacity: 1,
                      transition: "opacity 0.3s ease-in-out",
                    },
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      zIndex: 2,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "20%",
                      opacity: 0,
                      transition: "opacity 0.3s ease-in-out",
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={service.image}
                    alt={service.title}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover", // Ensures the image fits well in the container
                      borderRadius: "20%",
                      display: "block",
                      position: "relative",
                      zIndex: 1, // Ensures the image is under the gradient
                      transition: "transform 0.3s ease-in-out", // Smooth scaling animation
                    }}
                  />
                </Box>
                <CardContent
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#0066cc",
                      fontSize: "1rem",
                    }}
                  >
                    {service.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Call-to-Action */}
      <Box sx={{ mt: 6 }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/orthopedic");
          }}
          sx={{
            textTransform: "none",
            color: "#0066cc",
            fontWeight: "bold",
            backgroundColor: "transparent",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#004c99",
              boxShadow: "none",
            },
          }}
        >
          View All Services & Treatments →
        </Button>
      </Box>
    </Box>
  );
};

export default Orthopedic;
