// MedicalGallery.tsx

import React from "react";
import { Box, Typography, useTheme, useMediaQuery, Paper } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

interface GalleryImage {
  image: string;
  title: string;
  description: string;
}

const galleryImages: GalleryImage[] = [
  {
    image:
      "https://amamedicalgroup.com/wp-content/uploads/2022/09/What-Does-Preventative-Medicine-Mean-4.png",
    title: "Preventive Medicine",
    description:
      "Proactive measures to maintain your health and prevent diseases.",
  },
  {
    image:
      "https://northeastlondon.icb.nhs.uk/wp-content/uploads/2024/04/Vaccination-stock-image-2-scaled.jpg",
    title: "Vaccinations",
    description: "Essential vaccines to protect you and your loved ones.",
  },
  {
    image:
      "https://thryvemedica.ca/wp-content/uploads/2023/05/chronic-disease-prevention-and-management.jpg",
    title: "Chronic Disease Management",
    description:
      "Comprehensive care plans tailored to manage chronic conditions.",
  },
  {
    image:
      "https://hospitalsmagazine.com/wp-content/uploads/2023/05/Electrocardiogram.jpg",
    title: "Electrocardiograms (ECG)",
    description: "Advanced ECG tests to monitor your heart's health.",
  },
  {
    image:
      "https://cacvi.org/wp-content/uploads/2020/01/patient-stress-examination-1400x930.jpg",
    title: "Stress Tests",
    description: "Evaluate your heart's performance under physical stress.",
  },
  {
    image:
      "https://onewelbeck.com/media/kbrhtsf5/1wel_hh_0401.jpg",
    title: "Angiograms",
    description:
      "Detailed imaging of your blood vessels for accurate diagnosis.",
  },
  // Add more images as needed
];

const MedicalGallery: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      id="medical-gallery"
      sx={{
        py: 8,
        px: { xs: 2, sm: 4, md: 12 },
        backgroundImage: `url('https://png.pngtree.com/background/20211216/original/pngtree-medical-technology-background-picture-image_1509047.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          bgcolor: "rgba(255, 255, 255, 0.5)", // Semi-transparent overlay for readability
          zIndex: 1,
        },
      }}
    >
      {/* Content Container */}
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        {/* Section Heading */}
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "Avenir Next LT Pro",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
        >
          Medical Procedures Gallery
        </Typography>

        {/* Subheading */}
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "Roboto",
            color: "text.secondary",
            maxWidth: 800,
            mx: "auto",
            mb: 6,
          }}
        >
          Explore our state-of-the-art medical procedures that ensure your
          health and well-being.
        </Typography>

        {/* Swiper Slideshow */}
        <Swiper
          modules={[Pagination, Autoplay, EffectCoverflow]}
          spaceBetween={50}
          slidesPerView={isMobile ? 1 : 2}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          effect="coverflow"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          loop={true}
          style={{ paddingBottom: "50px" }}
        >
          {galleryImages.map((item, index) => (
            <SwiperSlide key={index}>
              <Paper
                elevation={4}
                sx={{
                  borderRadius: 2,
                  overflow: "hidden",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* Procedure Image */}
                <Box
                  component="img"
                  src={item.image}
                  alt={item.title}
                  sx={{
                    width: "100%",
                    height: { xs: "200px", sm: "250px", md: "300px" },
                    objectFit: "cover",
                    transition: "transform 0.5s ease",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                />

                {/* Procedure Details */}
                <Box sx={{ p: 3, flexGrow: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: theme.palette.primary.dark,
                      mb: 1,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                </Box>
              </Paper>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default MedicalGallery;
